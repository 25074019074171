import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddGetStartedMeals, AddGetStartedWorkouts, ClearClientDetails, ClearGetStarted, GetClientDetails, GetClientMealSettings, InsertEditTrainerClientMedia, ViewActivityLevels, ViewExperienceLevels, ViewGoals, ViewInjuries, UpdateTrainerClientNote, UploadToPresignedUrl } from '../../Services/Actions';
import history from '../../history';
import queryString from 'query-string';

import { AnswerText, AdditionalInformationContainer, InformationContainer, QuestionsContainer, ProfilePhotoContainer, TitleText } from './styles';
import { ActivityLevelContainer, ActivityLevelDetailsContainer, ActivityLevelsContainer, AddGymNoteContainer, Container, DailyCalorieContainer, DefaultGymContainer, DropdownContainer, ExperienceLevelContainer, ExperienceLevelsContainer, GymIcon, GymLogo, HeightFeetIcon, HeightInput, MultiUnit, MultiUnitContainer, NumberOfMeal, NumberOfMealsContainer, UnitInput, UnitInputContainer } from './styles';
import { ButtonIcon, MediaPreview, MultiSelect, StyledExternalLink, StyledMediaDocumentPreview, StyledMediaPreview, StyledVideoPreview, UploadButtonsContainer, UploadButtonsRow, UploadContainer, UploadDocumentsContainer } from './styles';
import { JournalArea, MobileBottom, MobileTop, StyledHR, TopArea, TopAreaBottom, TopAreaItem1Parent, TopAreaItem1, TopAreaItem2, TopAreaTop } from './styles';

import Accordion from '../../Components/Accordion';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Dropdown from '../../Components/Dropdown';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';
import TextArea from '../../Components/Form/TextArea';
import UploadProgressComponent from '../../Components/UploadProgress';

import ClientJournal from '../ClientJournal';
import ClientMeals from '../ClientMeals';
import ClientProgress from '../ClientProgress';
import ClientTasksStatuses from '../ClientTasksStatuses';
import ClientWorkouts from '../ClientWorkouts';

import ChooseGym from '../../Modals/ChooseGym';
import Info from '../../Modals/Info';
import PreviewMediaModal from '../../Modals/PreviewMedia';

import Camera from '../../Assets/Icons/Camera.png';
import GainMuscleMass from '../../Assets/Illustrations/GainMuscleMass.png';
import GetHealthy from '../../Assets/Illustrations/Food.png';
import LoseWeight from '../../Assets/Illustrations/LoseWeight.png';
import Gym from '../../Assets/Icons/Gym.png';
import PDF from '../../Assets/Icons/PDF.png';

import { CreateUploadToPresignedUrlPromise, getFileExtension } from '../../Functions';

class ClientDetail extends React.Component {
    _isMounted = false;

    state = {
        GetStartedQuestionAnswers: {
            ActivityLevel: null,
            AllergyIds: [],
            Allergies_Restrictions: null,
            CustomCalories: 0,
            DailyCalorieType: null,
            DietTypeId: 1,
            GoalId: null,
            Goal_CustomCalories: null,
            NumberOfMeals: null,

            Height: 0,
            HeightUnit: null,
            Height_Feet: 0,
            Height_Inches: 0,

            DefaultGym: {},
            DefaultGymId: null,
            DefaultGymImageLoaded: false,
            ExperienceLevel_Endurance: null,
            ExperienceLevel_Strength: null,
            HasGym: null,
            InjuriesString: null,
            JointIds: [],
            MainGym: '',
            MuscleIds: [],

            Weight: 0,
            WeightString: '',
            WeightUnit: null
        },
        DeleteMedia: [],
        GetStartedQuestionAnswers_Original: {},
        ImageTooLarge: false,
        Loading: true,
        MaxFileSizesKB: 102400,
        MediaTooLarge: false,
        NeedsRefresh_Meals: false,
        NeedsRefresh_Tasks: false,
        NeedsRefresh_Workouts: false,
        Note: '',
        PreviewMedia: null,
        PreviewMediaFileType: null,
        PreviewMediaUrl: '',
        SelectedImage: null,
        SelectedMedia: [],
        SelectedTab: this.props.SelectedTab || 'Progress',
        ShowChooseGymModal: false,
        ShowEditNote: false,
        ShowEditActivityLevelModal: false,
        ShowEditDietaryRestrictionsModal: false,
        ShowEditExperienceLevel_EnduranceModal: false,
        ShowEditExperienceLevel_StrengthModal: false,
        ShowEditGoal_CustomCaloriesModal: false,
        ShowEditHeightModal: false,
        ShowEditInjuriesModal: false,
        ShowEditMainGymModal: false,
        ShowEditNumberOfMealsModal: false,
        ShowEditWeightModal: false,
        ShowFullscreenMediaPreview: false,
        ShowJournal: false,
        TrainerClientMedia: { Documents: [], FileSize_KB: 0 },
        UploadMedia: []
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId } = this.props.match.params;

        if (!+ClientId) history.push('/');
        else {
            this.props.GetClientDetails(ClientId).then(() => {
                if (this._isMounted && !this.props.TryingGetClientDetailsError) this.updateClientDetailsToState({});
                else this.setState({ Loading: false });
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        if (!this.props.KeepCurrentClient) this.props.ClearClientDetails();
        this.props.ClearGetStarted();
    }

    componentDidUpdate(prevProps) {
        if (+prevProps.match.params.ClientId !== +this.props.match.params.ClientId) {
            this.props.GetClientDetails(this.props.match.params.ClientId).then(() => {
                if (this._isMounted && !this.props.TryingGetClientDetailsError) this.updateClientDetailsToState({});
            });
        }

        if (prevProps.location.search !== this.props.location.search) {
            var QueryValues = queryString.parse(this.props.location.search);
            if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
                if (QueryValues.tab.toLowerCase() === 'meals') this.setState({ SelectedTab: 'Meals' });
                else if (QueryValues.tab.toLowerCase() === 'progress') this.setState({ SelectedTab: 'Progress' });
                else if (QueryValues.tab.toLowerCase() === 'workouts') this.setState({ SelectedTab: 'Workouts' });
                else if (QueryValues.tab.toLowerCase() === 'tasks') this.setState({ SelectedTab: 'Tasks' });
                else if (QueryValues.tab.toLowerCase() === 'journal') this.setState({ SelectedTab: 'Journal' });
            }
        }

        // if ((!!!+prevProps.ClientDetails.GetStartedCompleted_Workouts && !!+this.props.ClientDetails.GetStartedCompleted_Workouts) || (!!!+prevProps.ClientDetails.GetStartedCompleted_Meals && !!+this.props.ClientDetails.GetStartedCompleted_Meals)) {
        //     this.updateClientDetailsToState({ SamePage: true });
        // }
    }

    updateClientDetailsToState = ({ SamePage }) => {
        var { ActivityLevel, Allergies, Allergies_Restrictions, ClientNote, CustomCalories, DefaultGym, DietTypeId, DishesInADay, ExperienceLevel_Endurance, ExperienceLevel_Strength, GoalId, Goal_CustomCalories, Height, HeightString, Injuries, InjuriesString, MainGym, TrainerClientMedia, UserUnits, Weight, WeightString } = this.props.ClientDetails;

        if (UserUnits.toLowerCase() === 'imperial') {
            var Height_Feet = Math.floor(Height / 12);
            var Height_Inches = Math.floor(Height - (Height_Feet * 12));
        }

        var DailyCalorieType = GoalId ? 'abel' : 'custom';

        var data = {
            ActivityLevel,
            AllergyIds: Allergies && Allergies.map(({ AllergyId }) => AllergyId),
            Allergies_Restrictions,
            CustomCalories,
            DailyCalorieType,
            DefaultGym,
            DefaultGymId: DefaultGym ? +DefaultGym.GymId : 1,
            DefaultGymImageLoaded: false,
            DietTypeId,
            ExperienceLevel_Endurance, ExperienceLevel_Strength,
            GoalId,
            Goal_CustomCalories,
            HasGym: !!(DefaultGym && +DefaultGym.GymId !== 1 && +DefaultGym.GymId !== 2),
            Height, HeightString, HeightUnit: UserUnits.toLowerCase() === 'imperial' ? 'FT' : 'CM', Height_Feet, Height_Inches,
            InjuriesString,
            JointIds: Injuries && Injuries.Joints.map(({ JointId }) => +JointId),
            MainGym,
            MuscleIds: Injuries && Injuries.Muscles.map(({ MuscleId }) => +MuscleId),
            NumberOfMeals: DishesInADay,
            Weight, WeightString, WeightUnit: UserUnits.toLowerCase() === 'imperial' ? 'LBS' : 'KG'
        };

        if (!SamePage) {
            const QueryValues = queryString.parse(this.props.location.search);
            var SelectedTab = 'Workouts';
            if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
                if (QueryValues.tab.toLowerCase() === 'meals') SelectedTab = 'Meals';
                else if (QueryValues.tab.toLowerCase() === 'progress') SelectedTab = 'Progress';
                else if (QueryValues.tab.toLowerCase() === 'workouts') SelectedTab = 'Workouts';
                else if (QueryValues.tab.toLowerCase() === 'tasks') SelectedTab = 'Tasks';
                else if (QueryValues.tab.toLowerCase() === 'journal') SelectedTab = 'Journal';
            }
            // Go to HomeScreen for User
                else history.push({ pathname: `/client/${this.props.match.params.ClientId}`, search: `?tab=${SelectedTab.toLowerCase()}` });
        }

        this.setState({
            GetStartedQuestionAnswers: data,
            GetStartedQuestionAnswers_Original: data,
            Loading: false,
            Note: ClientNote || '',
            SelectedTab: SelectedTab ? SelectedTab : this.props.location.state && this.props.location.state.SelectedTab ? this.props.location.state.SelectedTab : this.state.SelectedTab,
            ShowJournal: false,
            TrainerClientMedia
        }, () => window.history.replaceState({}, ''));
    }

    onChangeNote = event => {
        var Note = event.target.value;

        this.setState({ Note }, () => this.props.UpdateTrainerClientNote(this.props.match.params.ClientId, Note));
    }

    onChangeUnitInput = (event, StateName) => {
        var InputValue = event.target.value;
        
        if (!isNaN(InputValue)) {
            if (StateName === 'Height_Feet' || StateName === 'Height_Inches') {
                var { Height_Feet, Height_Inches } = this.state.GetStartedQuestionAnswers;
                Height_Feet = +Height_Feet;
                Height_Inches = +Height_Inches;

                Height_Feet = StateName === 'Height_Feet' ? InputValue : Height_Feet;
                Height_Inches = StateName === 'Height_Inches' ? InputValue >= 12 ? 11 : InputValue : Height_Inches;

                this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, Height_Feet, Height_Inches } });
            }
            else this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, [StateName]: InputValue } });
        }
    }

    onChangeUnitSelection = (StateName, UnitName) => {
        var CurrentUnit = this.state.GetStartedQuestionAnswers[StateName];
        var CurrentValue, NewValue;

        if (StateName === 'HeightUnit' && CurrentUnit !== UnitName) {
            var { Height, Height_Feet, Height_Inches } = this.state.GetStartedQuestionAnswers;
            Height = +Height;
            Height_Feet = +Height_Feet;
            Height_Inches = +Height_Inches;

            if (CurrentUnit === 'CM') {
                NewValue = Height ? Math.round((Height / 2.54) * 10) / 10 : Height;
                Height_Feet = Math.floor(NewValue / 12);
                Height_Inches = Math.floor(NewValue - (Height_Feet * 12));
            }
            else if (CurrentUnit === 'FT') {
                Height = Height_Feet ? Math.round((((Height_Feet * 12) + Height_Inches) * 2.54) * 10) / 10 : Height;
            }

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, Height, Height_Feet, Height_Inches, HeightUnit: UnitName } });
        }
        else if (StateName === 'WeightUnit' && CurrentUnit !== UnitName) {
            CurrentValue = +this.state.GetStartedQuestionAnswers.Weight;

            if (CurrentUnit === 'KG') NewValue = CurrentValue ? Math.round((CurrentValue * 2.20462) * 10) / 10 : CurrentValue;
            else if (CurrentUnit === 'LBS') NewValue = CurrentValue ? Math.round((CurrentValue / 2.20462) * 10) / 10 : CurrentValue;

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, Weight: NewValue, WeightUnit: UnitName } });
        }
    }

    onDeleteMedia = ({ event, DeleteMediaIndex, DocumentId, FileSize_KB, IsNewMedia, MediaUrl }) => {
        if (event) event.preventDefault();

        FileSize_KB = (!!IsNewMedia || DeleteMediaIndex === -1) ? this.state.TrainerClientMedia.FileSize - FileSize_KB : this.state.TrainerClientMedia.FileSize + FileSize_KB;
        FileSize_KB = Math.round(FileSize_KB * 10) / 10;

        if (!!IsNewMedia) {
            for (var i = 0; i < this.state.SelectedMedia.length; i++) {
                if (this.state.SelectedMedia[i].MediaUrl === MediaUrl) {
                    var SelectedIndex = i;
                    break;
                }
            }

            this.setState({
                MediaTooLarge: 0,
                SelectedMedia: [ ...this.state.SelectedMedia.slice(0, SelectedIndex), ...this.state.SelectedMedia.slice(SelectedIndex + 1) ],
                TrainerClientMedia: { ...this.state.TrainerClientMedia, FileSize_KB },
                UploadMedia: [ ...this.state.UploadMedia.slice(0, SelectedIndex), ...this.state.UploadMedia.slice(SelectedIndex + 1) ]
            });
        } else {
            if (DeleteMediaIndex === -1) this.setState({ DeleteMedia: [ ...this.state.DeleteMedia, DocumentId ], MediaTooLarge: 0, TrainerClientMedia: { ...this.state.TrainerClientMedia, FileSize: FileSize_KB } });
            else {
                if (FileSize_KB > this.state.MaxFileSizesKB) this.setState({ MediaTooLarge: FileSize_KB });
                else {
                    this.setState({
                        DeleteMedia: [ ...this.state.DeleteMedia.slice(0, DeleteMediaIndex), ...this.state.DeleteMedia.slice(DeleteMediaIndex + 1) ],
                        MediaTooLarge: 0,
                        TrainerClientMedia: { ...this.state.TrainerClientMedia, FileSize: FileSize_KB }
                    });
                }
            }
        }
    }

    onEditActivityLevelModal = () => {
        var ActivityLevel = +this.state.GetStartedQuestionAnswers.ActivityLevel;
        var OriginalActivityLevel = +this.state.GetStartedQuestionAnswers_Original.ActivityLevel;

        if (ActivityLevel === OriginalActivityLevel) this.setState({ ShowEditActivityLevelModal: false });
        else this.setState({ ShowEditActivityLevelModal: false }, () => this.onUpdateGetStartedQuestions(true));
    }

    onEditDietaryRestrictionsModal = () => {
        var { Allergies, DietTypes } = this.props;
        var { GetStartedQuestionAnswers: { AllergyIds, DietTypeId }, GetStartedQuestionAnswers_Original: { AllergyIds: OriginalAllergyIds, DietTypeId: OriginalDietTypeId } } = this.state;

        var AllergyIdsString = AllergyIds.sort().join(',');
        var OriginalAllergyIdsString = OriginalAllergyIds.sort().join(',');

        if (AllergyIdsString === OriginalAllergyIdsString && +DietTypeId === +OriginalDietTypeId) this.setState({ ShowEditDietaryRestrictionsModal: false });
        else {
            var AllergyList = [];
            if (DietTypeId === 3) {
                for (var i = 0; i < DietTypes.length; i++) {
                    if (+DietTypes[i].DietTypeId === 3) { AllergyList.push(DietTypes[i].DietTypeName); break; }
                }
            }

            if (AllergyIds.length) AllergyList = [ ...AllergyList, ...Allergies.filter(({ AllergyId }) => AllergyIds.indexOf(+AllergyId) > -1).map(({ AllergyName }) => AllergyName) ];

            var Allergies_Restrictions = AllergyList.join(', ');

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, Allergies_Restrictions }, ShowEditDietaryRestrictionsModal: false }, () => this.onUpdateGetStartedQuestions(false));
        }
    }

    onEditExperienceLevelModal = ExperienceLevelType => {
        var ExperienceLevel = +this.state.GetStartedQuestionAnswers[`ExperienceLevel_${ExperienceLevelType}`];
        var OriginalExperienceLevel = +this.state.GetStartedQuestionAnswers_Original[`ExperienceLevel_${ExperienceLevelType}`];

        if (ExperienceLevel === OriginalExperienceLevel) this.setState({ [`ShowEditExperienceLevel_${ExperienceLevelType}Modal`]: false });
        else this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, [`ExperienceLevel_${ExperienceLevelType}`]: ExperienceLevel }, [`ShowEditExperienceLevel_${ExperienceLevelType}Modal`]: false }, () => this.onUpdateGetStartedQuestions(false));
    }

    onEditGoal_CustomCaloriesModal = () => {
        var { GetStartedQuestionAnswers: { CustomCalories, DailyCalorieType, Goal_CustomCalories, GoalId }, GetStartedQuestionAnswers_Original: { CustomCalories: OriginalCustomCalories, DailyCalorieType: OriginalDailyCalorieType, GoalId: OriginalGoalId } } = this.state;
        
        if (DailyCalorieType === OriginalDailyCalorieType && ((DailyCalorieType === 'custom' && +CustomCalories === +OriginalCustomCalories) || (DailyCalorieType === 'abel' && +GoalId === +OriginalGoalId))) this.setState({ GetStartedQuestionAnswers: this.state.GetStartedQuestionAnswers_Original, ShowEditGoal_CustomCaloriesModal: false });
        else {
            if (DailyCalorieType === 'custom') {
                GoalId = null;
                Goal_CustomCalories = `${CustomCalories} kcal`;
            } else CustomCalories = 0;

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, CustomCalories, DailyCalorieType, Goal_CustomCalories, GoalId }, ShowEditGoal_CustomCaloriesModal: false }, () => this.onUpdateGetStartedQuestions(true));
        }
    }

    onEditHeightModal = () => {
        var { GetStartedQuestionAnswers: { Height, Height_Feet, Height_Inches, HeightUnit }, GetStartedQuestionAnswers_Original: { Height: OriginalHeight, Height_Feet: OriginalHeight_Feet, Height_Inches: OriginalHeight_Inches, HeightUnit: OriginalHeightUnit } } = this.state;

        if (HeightUnit === OriginalHeightUnit && ((HeightUnit === 'CM' && +Height === +OriginalHeight) || (HeightUnit === 'FT' && +Height_Feet === +OriginalHeight_Feet && +Height_Inches === +OriginalHeight_Inches))) this.setState({ ShowEditHeightModal: false });
        else {
            var HeightString = `${HeightUnit === 'CM' ? `${Height} cm` : `${Height_Feet}'${Height_Inches}"`}`;

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HeightString }, ShowEditHeightModal: false }, () => this.onUpdateGetStartedQuestions(true));
        }
    }

    onEditInjuriesModal = () => {
        var { Joints, Muscles } = this.props;
        var { GetStartedQuestionAnswers: { JointIds, MuscleIds }, GetStartedQuestionAnswers_Original: { JointIds: OriginalJointIds, MuscleIds: OriginalMuscleIds } } = this.state;

        var JointsString = JointIds.sort().join(',');
        var MusclesString = MuscleIds.sort().join(',');
        var OriginalJointsString = OriginalJointIds.sort().join(',');
        var OriginalMusclesString = OriginalMuscleIds.sort().join(',');

        if (JointsString === OriginalJointsString && MusclesString === OriginalMusclesString) this.setState({ ShowEditInjuriesModal: false });
        else {
            var InjuriesList = [];
            if (MuscleIds.length) InjuriesList = [ ...Muscles.filter(({ MuscleId }) => MuscleIds.indexOf(+MuscleId) > -1).map(({ MuscleName }) => MuscleName) ];
            if (JointIds.length) InjuriesList = [ ...InjuriesList, ...Joints.filter(({ JointId }) => JointIds.indexOf(+JointId) > -1).map(({ JointName }) => JointName) ];

            var InjuriesString = InjuriesList.sort().join(', ');

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, InjuriesString }, ShowEditInjuriesModal: false }, () => this.onUpdateGetStartedQuestions(false));
        }
    }

    onEditMainGymModal = () => {
        var { t } = this.props;
        var { GetStartedQuestionAnswers: { DefaultGym, DefaultGymId }, GetStartedQuestionAnswers_Original: { DefaultGymId: OriginalDefaultGymId } } = this.state;

        if (+DefaultGymId === +OriginalDefaultGymId) this.setState({ ShowEditMainGymModal: false });
        else {
            var MainGym = +DefaultGymId === 1 ? t('No_gym') : +DefaultGymId === 2 ? t('ptadmin_client_questionnaire_choosegym_allequipment') : DefaultGym.GymName;

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, MainGym }, ShowEditMainGymModal: false }, () => this.onUpdateGetStartedQuestions(false));
        }
    }

    onEditNumberOfMealsModal = () => {
        if (+this.state.GetStartedQuestionAnswers.NumberOfMeals === +this.state.GetStartedQuestionAnswers_Original.NumberOfMeals) this.setState({ ShowEditNumberOfMealsModal: false });
        else this.setState({ ShowEditNumberOfMealsModal: false }, () => this.onUpdateGetStartedQuestions(false));
    }

    onEditWeightModal = () => {
        var { GetStartedQuestionAnswers: { Weight, WeightUnit }, GetStartedQuestionAnswers_Original: { Weight: OriginalWeight, WeightUnit: OriginalWeightUnit } } = this.state;

        if (+Weight === +OriginalWeight && WeightUnit === OriginalWeightUnit) this.setState({ ShowEditWeightModal: false });
        else {
            var WeightString = `${Weight} ${WeightUnit === 'KG' ? 'kg' : 'lbs'}`;

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, WeightString }, ShowEditWeightModal: false }, () => this.onUpdateGetStartedQuestions(true));
        }
    }

    onLoadedDefaultGymImage = () => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, DefaultGymImageLoaded: true } });
    }

    onSelectActivityLevel = ActivityLevel => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, ActivityLevel } });
    }
    
    onSelectAllergy = (event, AllergyId, AllergyIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = AllergyIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, AllergyIds: [ ...prevState.GetStartedQuestionAnswers.AllergyIds, ...[ AllergyId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, AllergyIds: [ ...prevState.GetStartedQuestionAnswers.AllergyIds.slice(0, AllergyIndex), ...prevState.GetStartedQuestionAnswers.AllergyIds.slice(AllergyIndex + 1) ] }}
            });
        }
    }

    onSelectDailyCalorieType = DailyCalorieType => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, DailyCalorieType } });
    }

    onSelectDietTypeId = DietTypeId => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, DietTypeId } });
    }

    onSelectExperienceLevel = (ExperienceLevelType, ExperienceLevel) => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, [`ExperienceLevel_${ExperienceLevelType}`]: ExperienceLevel } });
    }

    onSelectGoal = (GoalId, GoalName) => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, Goal_CustomCalories: GoalName, GoalId } });
    }

    onSelectGym = DefaultGym => {
        this.setState({
            GetStartedQuestionAnswers: {
                ...this.state.GetStartedQuestionAnswers,
                DefaultGym,
                DefaultGymId: DefaultGym.GymId,
                DefaultGymImageLoaded: false,
                HasGym: true
            },
            ShowChooseGymModal: false
        });
    }

    onSelectHasGym = (HasGym, DefaultGymId) => {
        if (HasGym) this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HasGym }, ShowChooseGymModal: true });
        else this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HasGym, DefaultGym: {}, DefaultGymId } });
    }

    onSelectJoint = (event, JointId, JointIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = JointIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, JointIds: [ ...prevState.GetStartedQuestionAnswers.JointIds, ...[ JointId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, JointIds: [ ...prevState.GetStartedQuestionAnswers.JointIds.slice(0, JointIndex), ...prevState.GetStartedQuestionAnswers.JointIds.slice(JointIndex + 1) ] }}
            });
        }
    }

    onSelectMuscle = (event, MuscleId, MuscleIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = MuscleIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, MuscleIds: [ ...prevState.GetStartedQuestionAnswers.MuscleIds, ...[ MuscleId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, MuscleIds: [ ...prevState.GetStartedQuestionAnswers.MuscleIds.slice(0, MuscleIndex), ...prevState.GetStartedQuestionAnswers.MuscleIds.slice(MuscleIndex + 1) ] }}
            });
        }
    }

    onSelectNoteMedia = ({ event }) => {
        var FileSize = 0;
        var Files = event.target.files;
        var NumFiles = Files.length;

        var i;

        if (NumFiles > 0) {
            for (i = 0; i < NumFiles; i++) {
                FileSize += Math.round((Files[i].size / 1000) * 10) / 10;
            }

            var TotalUsedSize = FileSize + this.state.TrainerClientMedia.FileSize;

            if (TotalUsedSize > this.state.MaxFileSizesKB) this.setState({ MediaTooLarge: TotalUsedSize });
            else {
                var SelectedMedia = [];

                for (i = 0; i < NumFiles; i++) {
                    SelectedMedia.push({ FileExtension: getFileExtension(Files[i].name), FileName: Files[i].name, FileSize_KB: Math.round((Files[i].size / 1000) * 10) / 10, IsNewMedia: true, MediaUrl: URL.createObjectURL(Files[i]) });
                }

                this.setState({ MediaTooLarge: 0, TrainerClientMedia: { ...this.state.TrainerClientMedia, FileSize: TotalUsedSize }, UploadMedia: [ ...this.state.UploadMedia, ...Files ], SelectedMedia: [ ...this.state.SelectedMedia, ...SelectedMedia ] });
            }

            document.getElementById('NoteMedia').value = null;
        }
    }

    onSelectNumberOfMeals = NumberOfMeals => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, NumberOfMeals } });
    }

    onSelectTab = SelectedTab => {
        history.push({ pathname: `/client/${this.props.match.params.ClientId}`, search: `?tab=${SelectedTab.toLowerCase()}` });
        // this.setState({ SelectedTab });
    }

    onToggleChooseGymModal = ShowChooseGymModal => {
        var { DefaultGymId } = this.state.GetStartedQuestionAnswers;

        var HasGym = true;
        if (!ShowChooseGymModal && ((DefaultGymId === null || DefaultGymId === 0))) HasGym = false;

        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HasGym }, ShowChooseGymModal });
    }

    onToggleNeedsRefresh = (SectionType, NeedsRefresh) => {
        this.setState({ [`NeedsRefresh_${SectionType}`]: NeedsRefresh }, () => NeedsRefresh && this.setState({ [`NeedsRefresh_${SectionType}`]: false }));
    }

    onToggleShowEditClientModal = (ModalName, Show, GetStartedParameter) => {
        if (!Show) {
            var GetStartedQuestionAnswers = { ...this.state.GetStartedQuestionAnswers };

            if (GetStartedParameter === 'Weight') {
                GetStartedQuestionAnswers = {
                    ...GetStartedQuestionAnswers,
                    Weight: this.state.GetStartedQuestionAnswers_Original.Weight,
                    WeightUnit: this.state.GetStartedQuestionAnswers_Original.WeightUnit
                }
            }
            else if (GetStartedParameter === 'Height') {
                GetStartedQuestionAnswers = {
                    ...GetStartedQuestionAnswers,
                    Height: this.state.GetStartedQuestionAnswers_Original.Height,
                    HeightUnit: this.state.GetStartedQuestionAnswers_Original.HeightUnit,
                    Height_Feet: this.state.GetStartedQuestionAnswers_Original.Height_Feet,
                    Height_Inches: this.state.GetStartedQuestionAnswers_Original.Height_Inches
                }
            }
            else if (GetStartedParameter === 'Goal_CustomCalories') {
                GetStartedQuestionAnswers = {
                    ...GetStartedQuestionAnswers,
                    CustomCalories: this.state.GetStartedQuestionAnswers_Original.CustomCalories,
                    DailyCalorieType: this.state.GetStartedQuestionAnswers_Original.DailyCalorieType,
                    GoalId: this.state.GetStartedQuestionAnswers_Original.GoalId,
                    Goal_CustomCalories: this.state.GetStartedQuestionAnswers_Original.Goal_CustomCalories
                }
            }
            else if (GetStartedParameter === 'DietaryRestrictions') {
                GetStartedQuestionAnswers = {
                    ...GetStartedQuestionAnswers,
                    AllergyIds: this.state.GetStartedQuestionAnswers_Original.AllergyIds,
                    DietTypeId: this.state.GetStartedQuestionAnswers_Original.DietTypeId,
                }
            }
            else if (GetStartedParameter === 'MainGym') {
                GetStartedQuestionAnswers = {
                    ...GetStartedQuestionAnswers,
                    DefaultGym: this.state.GetStartedQuestionAnswers_Original.DefaultGym,
                    DefaultGymId: this.state.GetStartedQuestionAnswers_Original.DefaultGymId,
                    HasGym: this.state.GetStartedQuestionAnswers_Original.HasGym
                }
            }
            else GetStartedQuestionAnswers = { ...GetStartedQuestionAnswers, [`${GetStartedParameter}`]: this.state.GetStartedQuestionAnswers_Original[`${GetStartedParameter}`] };

            this.setState({ [`${ModalName}`]: false, GetStartedQuestionAnswers });
        }
        else this.setState({ [`${ModalName}`]: true });
    }

    onToggleShowEditNote = () => {
        this.setState({ ShowEditNote: !this.state.ShowEditNote });
    }

    onToggleShowFullscreenMediaPreview = (PreviewMedia, PreviewMediaUrl, ShowFullscreenMediaPreview, PreviewMediaFileType) => {
        if (this._isMounted) this.setState({ PreviewMedia, PreviewMediaUrl, ShowFullscreenMediaPreview, PreviewMediaFileType });
    }

    onToggleShowJournal = () => {
        this.setState({ ShowJournal: !this.state.ShowJournal });
    }

    onUpdateGetStartedQuestions = NeedsRefresh => {
        var { SelectedTab } = this.state;
        var { ClientId } = this.props.match.params;

        var { GetStartedQuestionAnswers: { ActivityLevel, AllergyIds, CustomCalories, DailyCalorieType, DefaultGymId, DietTypeId, ExperienceLevel_Endurance, ExperienceLevel_Strength, GoalId, Height, Height_Feet, Height_Inches, HeightUnit, JointIds, MuscleIds, NumberOfMeals, Weight, WeightUnit } } = this.state;

        if (SelectedTab === 'Meals') {
            var Allergies = AllergyIds.join(',');

            HeightUnit = HeightUnit === 'FT' ? 'Imperial' : 'Metric';
            Height = HeightUnit === 'Imperial' ? (Number(Height_Feet) * 12) + Number(Height_Inches) : Number(Height);

            WeightUnit = WeightUnit === 'LBS' ? 'Imperial' : 'Metric';

            if (DailyCalorieType === 'abel') CustomCalories = 0;
            else if (DailyCalorieType === 'custom') GoalId = 0;

            this.props.AddGetStartedMeals({ ActivityLevel, Allergies, ClientId, CustomCalories, DietTypeId, GoalId, Height, HeightUnit, NumberOfMeals, Weight, WeightUnit }).then(() => {
                if (!this.props.TryingAddGetStartedMealsError) {
                    this.setState({ GetStartedQuestionAnswers_Original: this.state.GetStartedQuestionAnswers });
                    if (NeedsRefresh) this.onToggleNeedsRefresh('Meals', true);
                }
                else if (this.props.TryingAddGetStartedMealsError) this.setState({ GetStartedQuestionAnswers: this.state.GetStartedQuestionAnswers_Original });
            });
        } else if (SelectedTab === 'Workouts') {
            DefaultGymId = DefaultGymId || 1;
            var Joints = JointIds.join(',');
            var Muscles = MuscleIds.join(',');

            WeightUnit = WeightUnit === 'LBS' ? 'Imperial' : 'Metric';

            this.props.AddGetStartedWorkouts({ ClientId, DefaultGymId, ExperienceLevel_Endurance, ExperienceLevel_Strength, Joints, Muscles, Weight, WeightUnit }).then(() => {
                if (!this.props.TryingAddGetStartedWorkoutsError) this.setState({ GetStartedQuestionAnswers_Original: this.state.GetStartedQuestionAnswers });
                else if (this.props.TryingAddGetStartedWorkoutsError) this.setState({ GetStartedQuestionAnswers: this.state.GetStartedQuestionAnswers_Original });
            });
        }
    }

    onUploadNoteMedia = () => {
        var { ClientDetails: { ClientId } } = this.props;
        var { DeleteMedia, SelectedMedia, UploadMedia: NoteMedia } = this.state;

        var UploadMedia = JSON.stringify(NoteMedia.map(({ name: FileName, size: FileSize, type: ContentType }) => ({ ContentType, FileName, FileSize })));

        this.props.InsertEditTrainerClientMedia({ ClientId, DeleteMedia, NoteMedia: UploadMedia }).then(({ Documents, PreSignedUrls }) => {
            if (!this.props.TryingInsertEditTrainerClientMediaError) {
                if (NoteMedia.length > 0 && PreSignedUrls.length > 0) {
                    var UploadFunction = this.props.UploadToPresignedUrl;
                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'ClientNotes', File: NoteMedia[index], PresignedFormData: PreSignedUrl, UploadFunction }));

                    var NewDocs = SelectedMedia.map(NewDoc => ({ ...NewDoc, IsNewMedia: false, NewlySaved: true }));

                    Promise.all(Promises).then(() => {
                        if (NoteMedia.length === 0 && DeleteMedia.length === 0) { Documents = this.state.TrainerClientMedia.Documents; }

                        this.setState({ TrainerClientMedia: { ...this.state.TrainerClientMedia, Documents: [ ...Documents, ...NewDocs ] }, DeleteMedia: [], SelectedMedia: [], UploadMedia: [] });
                    });   
                } else {
                    if (NoteMedia.length === 0 && DeleteMedia.length === 0) { Documents = this.state.TrainerClientMedia.Documents; }

                    this.setState({ TrainerClientMedia: { ...this.state.TrainerClientMedia, Documents }, DeleteMedia: [], SelectedMedia: [], UploadMedia: [] });
                }
            }
        });
    }

    renderAdditionalInformation = () => {
        var { t } = this.props;
        var { SelectedTab } = this.state;

        return (
            <>
                <Accordion Text={t(SelectedTab === 'Workouts' ? 'Workout_settings' : 'Meal_settings')}>
                    <AdditionalInformationContainer>
                        {SelectedTab === 'Workouts' ? this.renderWorkoutQuestions() : this.renderMealQuestions()}
                    </AdditionalInformationContainer>
                </Accordion>
            </>            
        );
    }

    renderChooseGymModal = () => {
        var { GetStartedQuestionAnswers: { DefaultGymId }, ShowChooseGymModal } = this.state;

        if (ShowChooseGymModal) {
            return (
                <ChooseGym
                    ClientId={+this.props.match.params.ClientId}
                    DefaultGymId={DefaultGymId}
                    OnHide={() => this.onToggleChooseGymModal(false)}
                    OnSelectGym={DefaultGym => this.onSelectGym(DefaultGym)}
                    Show={ShowChooseGymModal}
                />
            )
        }
    }

    renderEditActivityLevelModal = () => {
        var { GetStartedQuestionAnswers: { ActivityLevel }, ShowEditActivityLevelModal } = this.state;

        if (ShowEditActivityLevelModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!Number(ActivityLevel)}
                    BottomButtonOnClick={this.onEditActivityLevelModal}
                    InformationRenderer={this.renderEditActivityLevelModal_Information}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditActivityLevelModal', false, 'ActivityLevel')}
                    Show={ShowEditActivityLevelModal}
                />
            );
        }
    }

    renderEditActivityLevelModal_Information = () => {
        var { t } = this.props;
        var { ActivityLevels, RetrievedActivityLevels, TryingGetActivityLevels } = this.props;
        
        if (!RetrievedActivityLevels && !TryingGetActivityLevels) this.props.ViewActivityLevels(this.props.match.params.ClientId);

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_activitylevel')} TextAlign="center" />
                
                <Spacer Size="extra-small" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_questiondescription_activitylevel')} TextAlign="center" />
                
                <Spacer Size="medium" />
                
                {
                    !RetrievedActivityLevels || TryingGetActivityLevels ?
                    <Loading />
                :
                    <ActivityLevelsContainer>
                        {
                            ActivityLevels.ActivityLevels.map(({ ActivityLevel, ActivityLevelDescription, ActivityLevelName }) => {
                                var SelectedActivityLevel = +this.state.GetStartedQuestionAnswers.ActivityLevel === +ActivityLevel ? 1 : 0;

                                return (
                                    <ActivityLevelContainer key={ActivityLevel} onClick={() => this.onSelectActivityLevel(ActivityLevel)} Selected={SelectedActivityLevel}>
                                        <PageText FontFamily="bold" FontSize="large" NoMargin Text={ActivityLevel} />
                                        <ActivityLevelDetailsContainer>
                                            <PageText FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ActivityLevelName} />
                                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={ActivityLevelDescription} />
                                        </ActivityLevelDetailsContainer>
                                    </ActivityLevelContainer>
                                );
                            })
                        }
                    </ActivityLevelsContainer>
                }
            </>
        );
    }

    renderEditDietaryRestrictionsModal = () => {
        var { ShowEditDietaryRestrictionsModal } = this.state;

        if (ShowEditDietaryRestrictionsModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onEditDietaryRestrictionsModal}
                    InformationRenderer={this.renderEditDietaryRestrictionsModal_Information}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditDietaryRestrictionsModal', false, 'DietaryRestrictions')}
                    Show={ShowEditDietaryRestrictionsModal}
                />
            );
        }
    }

    renderEditDietaryRestrictionsModal_Information = () => {
        var { t } = this.props;
        var { Allergies, Device, DietTypes, RetrievedDietaryRestrictions, TryingGetClientMealSettings } = this.props;

        if (!RetrievedDietaryRestrictions && !TryingGetClientMealSettings) this.props.GetClientMealSettings({ ClientId: this.props.match.params.ClientId });

        var { AllergyIds, DietTypeId } = this.state.GetStartedQuestionAnswers;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_dietaryrestrictions')} TextAlign="center" />
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                {
                    !RetrievedDietaryRestrictions || TryingGetClientMealSettings ?
                    <Loading />
                :
                    <DropdownContainer>
                        <Dropdown
                            ItemId="AllergyId"
                            ItemName="AllergyName"
                            Items={Allergies}
                            OnSelectItem={(event, AllergyId, AllergyIndex) => this.onSelectAllergy(event, AllergyId, AllergyIndex)}
                            SelectedItemIds={AllergyIds}
                            Title={t('WorkoutPlanSettings_Allergies')}
                        />
                        <Dropdown
                            ItemId="DietTypeId"
                            ItemName="DietTypeName"
                            Items={DietTypes}
                            OnSelectItem={(event, DietTypeId, DietTypeIndex) => this.onSelectDietTypeId(DietTypeId)}
                            SelectedItemIds={[ DietTypeId ]}
                            Title={t('WorkoutPlanSettings_Dietary_restrictions')}
                        />
                    </DropdownContainer>
                }
            </>
        );
    }

    renderEditExperienceLevel_EnduranceModal = () => {
        var { GetStartedQuestionAnswers: { ExperienceLevel_Endurance }, ShowEditExperienceLevel_EnduranceModal } = this.state;

        if (ShowEditExperienceLevel_EnduranceModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!ExperienceLevel_Endurance}
                    BottomButtonOnClick={() => this.onEditExperienceLevelModal('Endurance')}
                    InformationRenderer={() => this.renderExperienceLevels_Information('Endurance')}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditExperienceLevel_EnduranceModal', false, 'ExperienceLevel_Endurance')}
                    Show={ShowEditExperienceLevel_EnduranceModal}
                />
            );
        }
    }

    renderEditExperienceLevel_StrengthModal = () => {
        var { GetStartedQuestionAnswers: { ExperienceLevel_Strength }, ShowEditExperienceLevel_StrengthModal } = this.state;

        if (ShowEditExperienceLevel_StrengthModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!ExperienceLevel_Strength}
                    BottomButtonOnClick={() => this.onEditExperienceLevelModal('Strength')}
                    InformationRenderer={() => this.renderExperienceLevels_Information('Strength')}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditExperienceLevel_StrengthModal', false, 'ExperienceLevel_Strength')}
                    Show={ShowEditExperienceLevel_StrengthModal}
                />
            );
        }
    }

    renderExperienceLevels_Information = ExperienceLevelType => {
        var { t } = this.props;
        var { ExperienceLevels, RetrievedExperienceLevels, TryingGetExperienceLevels } = this.props;

        if (!RetrievedExperienceLevels && !TryingGetExperienceLevels) this.props.ViewExperienceLevels(this.props.match.params.ClientId);

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t(`ptadmin_client_questionnaire_questiontitle_experiencelevel_${ExperienceLevelType.toLowerCase()}`)} TextAlign="center" />
                
                <Spacer Size="medium" />
                
                {
                    !RetrievedExperienceLevels || TryingGetExperienceLevels ?
                    <Loading />
                :
                    <ExperienceLevelsContainer>
                        {
                            ExperienceLevels[ExperienceLevelType].map(({ ExperienceLevel, ExperienceLevelDescription }) => {
                                var SelectedExperienceLevel = +this.state.GetStartedQuestionAnswers[`ExperienceLevel_${ExperienceLevelType}`] === +ExperienceLevel ? 1 : 0;

                                return (
                                    <ExperienceLevelContainer key={ExperienceLevel} onClick={() => this.onSelectExperienceLevel(ExperienceLevelType, ExperienceLevel)} Selected={SelectedExperienceLevel}>
                                        <PageText FontFamily="bold" FontSize="large" NoMargin Text={ExperienceLevel} />

                                        <Spacer Size="extra-small" />

                                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={ExperienceLevelDescription} />
                                    </ExperienceLevelContainer>
                                );
                            })
                        }
                    </ExperienceLevelsContainer>
                }
            </>
        );
    }

    renderEditGoal_CustomCaloriesModal = () => {
        var { GetStartedQuestionAnswers: { CustomCalories, DailyCalorieType, GoalId }, ShowEditGoal_CustomCaloriesModal } = this.state;

        if (ShowEditGoal_CustomCaloriesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={(!DailyCalorieType || (DailyCalorieType === 'custom' && !CustomCalories) || (DailyCalorieType === 'abel' && !GoalId))}
                    BottomButtonOnClick={this.onEditGoal_CustomCaloriesModal}
                    InformationRenderer={this.renderEditGoal_CustomCaloriesModal_Information}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditGoal_CustomCaloriesModal', false, 'Goal_CustomCalories')}
                    Show={ShowEditGoal_CustomCaloriesModal}
                />
            );
        }
    }

    renderEditGoal_CustomCaloriesModal_Information = () => {
        var { RetrievedGoals, TryingGetGoals } = this.props;

        if (!RetrievedGoals && !TryingGetGoals) this.props.ViewGoals();

        var { t } = this.props;
        var { Device, Goals } = this.props;
        var { GetStartedQuestionAnswers: { DailyCalorieType, GoalId: StateGoalId } } = this.state;

        var BackgroundColorHover = 'blue-astronaut';
        var BackgroundColorSelected = 'blue-astronaut';
        var ColorHover = 'white';
        var ColorSelected = 'white';

        var ButtonStart = { BackgroundColorHover, BackgroundColorSelected, ColorHover, ColorSelected };

        var GoalButtons = [];
        if (Goals.length) GoalButtons = Goals.map(({ GoalId, GoalName }) => ({ ...ButtonStart, Disabled: DailyCalorieType !== 'abel', Icon: +GoalId === 1 ? LoseWeight : +GoalId === 3 ? GainMuscleMass : GetHealthy, IconPosition: 'top', IconSize: { Height: '70px', Width: '70px' }, OnClick: () => this.onSelectGoal(+GoalId, GoalName), Selected: +StateGoalId === +GoalId, Subtitle: t(+GoalId === 1 ? 'ClientGoal_Subtitle_LoseWeight' : +GoalId === 3 ? 'ClientGoal_Subtitle_GainMuscleMass' : 'ClientGoal_Subtitle_GetHealthy'), Title: GoalName }));

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_dailycalories')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                {
                    !RetrievedGoals || TryingGetGoals ?
                    <Loading />
                :
                    Device === 'mobile_small' || Device === 'mobile' ?
                    <>
                        <ButtonGroup
                            Buttons={[
                                { ...ButtonStart, OnClick: () => this.onSelectDailyCalorieType('custom'), Selected: DailyCalorieType === 'custom', Title: t('ptadmin_client_questionnaire_dailycalorie_option1') },
                            ]}
                            NormalWhiteSpace
                            NotTouching
                            OwnRows
                        />
                        
                        <Spacer Size="small" />

                        <DailyCalorieContainer Opacity={DailyCalorieType === 'custom' ? 1 : 0.5}>
                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_dailycalorie_label')} TextAlign="center" />
                            
                            <Spacer Size="small" />
                            
                            {this.renderUnitInput('CustomCalories', DailyCalorieType !== 'custom')}
                        </DailyCalorieContainer>

                        <Spacer Size="large" />

                        <ButtonGroup
                            Buttons={[
                                { ...ButtonStart, OnClick: () => this.onSelectDailyCalorieType('abel'), Selected: DailyCalorieType === 'abel', Title: t('ptadmin_client_questionnaire_dailycalorie_option2') }
                            ]}
                            NormalWhiteSpace
                            NotTouching
                            OwnRows
                        />

                        <Spacer Size="small" />

                        <DailyCalorieContainer Opacity={DailyCalorieType === 'abel' ? 1 : 0.5}>
                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_choosegoal_label')} TextAlign="center" />
                            
                            <Spacer Size="small" />

                            <ButtonGroup Buttons={GoalButtons} NormalWhiteSpace NotTouching OwnRows />
                        </DailyCalorieContainer>
                    </>
                :
                    <>
                        <ButtonGroup
                            Buttons={[
                                { ...ButtonStart, OnClick: () => this.onSelectDailyCalorieType('custom'), Selected: DailyCalorieType === 'custom', Title: t('ptadmin_client_questionnaire_dailycalorie_option1') },
                                { ...ButtonStart, OnClick: () => this.onSelectDailyCalorieType('abel'), Selected: DailyCalorieType === 'abel', Title: t('ptadmin_client_questionnaire_dailycalorie_option2') }
                            ]}
                            NormalWhiteSpace
                            NotTouching
                        />

                        <Spacer Size="small" />

                        <Container>
                            <DailyCalorieContainer Opacity={DailyCalorieType === 'custom' ? 1 : 0.5}>
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_dailycalorie_label')} TextAlign="center" />
                                
                                <Spacer Size="small" />
                                
                                {this.renderUnitInput('CustomCalories', DailyCalorieType !== 'custom')}
                            </DailyCalorieContainer>

                            <DailyCalorieContainer Opacity={DailyCalorieType === 'abel' ? 1 : 0.5}>
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_choosegoal_label')} TextAlign="center" />
                                
                                <Spacer Size="small" />

                                <ButtonGroup Buttons={GoalButtons} NormalWhiteSpace NotTouching OwnRows />
                            </DailyCalorieContainer>
                        </Container>
                    </>
                }
            </>
        );
    }

    renderEditHeightModal = () => {
        var { GetStartedQuestionAnswers: { Height, Height_Feet, HeightUnit }, ShowEditHeightModal } = this.state;

        if (ShowEditHeightModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={(HeightUnit === 'CM' && !Number(Height)) || (HeightUnit === 'FT' && !Number(Height_Feet))}
                    BottomButtonOnClick={this.onEditHeightModal}
                    InformationRenderer={this.renderEditHeightModal_Information}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditHeightModal', false, 'Height')}
                    Show={ShowEditHeightModal}
                />
            );
        }
    }

    renderEditHeightModal_Information = () => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_height')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                
                {this.renderUnitSelections('HeightUnit', 'FT', 'CM')}
                
                <Spacer Size="small" />
                
                {this.renderUnitInput('Height')}
            </>
        );
    }

    renderEditInjuriesModal = () => {
        var { ShowEditInjuriesModal } = this.state;

        if (ShowEditInjuriesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onEditInjuriesModal}
                    InformationRenderer={this.renderEditInjuriesModal_Information}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditInjuriesModal', false, 'Injuries')}
                    Show={ShowEditInjuriesModal}
                />
            );
        }
    }

    renderEditInjuriesModal_Information = () => {
        var { t } = this.props;
        var { Device, Joints, Muscles, RetrievedInjuries, TryingGetInjuries } = this.props;

        if (!RetrievedInjuries && !TryingGetInjuries) this.props.ViewInjuries(this.props.match.params.ClientId);

        var { JointIds, MuscleIds } = this.state.GetStartedQuestionAnswers;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_injuries')} TextAlign="center" />
                
                <Spacer Size="extra-small" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_questiondescription_injuries')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                {
                    !RetrievedInjuries || TryingGetInjuries ?
                    <Loading />
                :
                    <DropdownContainer>
                        <Dropdown
                            ItemId="JointId"
                            ItemName="JointName"
                            Items={Joints}
                            OnSelectItem={(event, JointId, JointIndex) => this.onSelectJoint(event, JointId, JointIndex)}
                            SelectedItemIds={JointIds}
                            Title={t('Joints')}
                        />
                        <Dropdown
                            ItemId="MuscleId"
                            ItemName="MuscleName"
                            Items={Muscles}
                            OnSelectItem={(event, MuscleId, MuscleIndex) => this.onSelectMuscle(event, MuscleId, MuscleIndex)}
                            SelectedItemIds={MuscleIds}
                            Title={t('Muscles')}
                        />
                    </DropdownContainer>
                }
            </>
        );
    }

    renderEditMainGymModal = () => {
        var { GetStartedQuestionAnswers: { DefaultGymId }, ShowChooseGymModal, ShowEditMainGymModal } = this.state;

        if (ShowEditMainGymModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={DefaultGymId === null}
                    BottomButtonOnClick={this.onEditMainGymModal}
                    DisplayNone={ShowChooseGymModal}
                    InformationRenderer={this.renderEditMainGymModal_Information}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditMainGymModal', false, 'MainGym')}
                    Show={ShowEditMainGymModal}
                />
            );
        }
    }

    renderEditMainGymModal_Information = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { DefaultGym: { GymCityAddress, GymImage, GymName, GymStreetAddress }, DefaultGymId, DefaultGymImageLoaded, HasGym } = this.state.GetStartedQuestionAnswers;

        var BackgroundColorHover = 'blue-astronaut';
        var BackgroundColorSelected = 'blue-astronaut';
        var ColorHover = 'white';
        var ColorSelected = 'white';

        var ButtonStart = { BackgroundColorHover, BackgroundColorSelected, ColorHover, ColorSelected };

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_defaultgym')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                <ButtonGroup
                    Buttons={[
                        { ...ButtonStart, OnClick: () => this.onSelectHasGym(true), Selected: HasGym, Title: t('ptadmin_client_questionnaire_choosegym_choosegym') },
                        { ...ButtonStart, OnClick: () => this.onSelectHasGym(false, 1), Selected: HasGym === false && DefaultGymId === 1, Title: t('ptadmin_client_questionnaire_choosegym_noequipment') },
                        { ...ButtonStart, OnClick: () => this.onSelectHasGym(false, 2), Selected: HasGym === false && DefaultGymId === 2, Title: t('ptadmin_client_questionnaire_choosegym_allequipment') }
                    ]}
                    ButtonWidth="fit-content"
                    NotTouching
                />

                {
                    (!!DefaultGymId && +DefaultGymId !== 1 && +DefaultGymId !== 2) ?
                    <>
                        <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                        <DefaultGymContainer>
                            <GymLogo ImageSrc={GymImage} Loading={!DefaultGymImageLoaded} OnLoaded={this.onLoadedDefaultGymImage} />
                            
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={GymName} TextAlign="center" />
                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={GymStreetAddress} TextAlign="center" />
                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={GymCityAddress} TextAlign="center" />
                        </DefaultGymContainer>
                    </>
                :
                    null
                }

                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                <AddGymNoteContainer>
                    <GymIcon alt="gym" src={Gym} />
                    <PageText FontFamily="medium-italic" FontSize="small" NoMargin Text={t('addgymnote')} TextAlign={Device === 'mobile_small' || Device === 'mobile' ? 'center' : 'left'} />
                </AddGymNoteContainer>
            </>
        );
    }

    renderEditNumberOfMealsModal = () => {
        var { GetStartedQuestionAnswers: { NumberOfMeals }, ShowEditNumberOfMealsModal } = this.state;

        if (ShowEditNumberOfMealsModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!NumberOfMeals}
                    BottomButtonOnClick={this.onEditNumberOfMealsModal}
                    InformationRenderer={this.renderEditNumberOfMealsModal_Information}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditNumberOfMealsModal', false, 'NumberOfMeals')}
                    Show={ShowEditNumberOfMealsModal}
                />
            );
        }
    }

    renderEditNumberOfMealsModal_Information = () => {
        var { t } = this.props;
        var { NumberOfMeals } = this.state.GetStartedQuestionAnswers;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_numberofmeals')} TextAlign="center" />
                
                <Spacer Size="extra-small" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_questiondescription_numberofmeals')} TextAlign="center" />
                
                <Spacer Size="medium" />
                
                <NumberOfMealsContainer className="NumberOfMealsContainer">
                    {
                        [ 1, 2, 3, 4, 5, 6, 7, 8 ].map(MealNumber => {
                            var SelectedNumberOfMeals = NumberOfMeals === MealNumber ? 1 : 0;

                            return (
                                <NumberOfMeal className="NumberOfMeal" key={MealNumber} onClick={() => this.onSelectNumberOfMeals(MealNumber)} Selected={SelectedNumberOfMeals}>
                                    {MealNumber}
                                </NumberOfMeal>
                            );
                        })
                    }
                </NumberOfMealsContainer>
            </>
        );
    }

    renderEditWeightModal = () => {
        var { GetStartedQuestionAnswers: { Weight }, ShowEditWeightModal } = this.state;

        if (ShowEditWeightModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!Weight}
                    BottomButtonOnClick={this.onEditWeightModal}
                    InformationRenderer={this.renderEditWeightModal_Information}
                    OnHide={() => this.onToggleShowEditClientModal('ShowEditWeightModal', false, 'Weight')}
                    Show={ShowEditWeightModal}
                />
            );
        }
    }

    renderEditWeightModal_Information = () => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_weight')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                
                {this.renderUnitSelections('WeightUnit', 'LBS', 'KG')}
                
                <Spacer Size="small" />
                
                {this.renderUnitInput('Weight')}
            </>
        );
    }

    renderJournal = () => {
        var { t } = this.props;
        var { TryingUpdateTrainerClientNote, UploadProgress } = this.props;
        var { Note } = this.state;

        return (
            <>
                {TryingUpdateTrainerClientNote && <Loading />}
                
                <Spacer Size="small" />

                <TopAreaBottom className="TopAreaBottom">
                    <JournalArea className="JournalArea">
                        <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('notes')} TextAlign="center" />

                        <Spacer Size="small" />

                        <TextArea
                            OnBlur={this.onChangeNote}
                            Placeholder={t('ptadmin_clientnote_placeholder')}
                            Value={Note}
                        />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Upload')} TextAlign="center" />

                        <Spacer Size="small" />

                        {this.renderUploadNoteMedia()}
                    </JournalArea>

                    {
                        UploadProgress.ClientNotes && Object.entries(UploadProgress.ClientNotes).length > 0 && 
                            <>
                                <Spacer Size="medium" />

                                <UploadProgressComponent UploadingItems={UploadProgress.ClientNotes} />
                            </>
                    }
                </TopAreaBottom>
            </> 
        );
    }

    renderMealQuestions = () => {
        var { t } = this.props;
        var { GetStartedQuestionAnswers: { ActivityLevel, Allergies_Restrictions, Goal_CustomCalories, HeightString, NumberOfMeals, WeightString } } = this.state;

        return (
            <>
                <QuestionsContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditGoal_CustomCaloriesModal', true)}>
                        <TitleText>{t('Goal_CustomCalories')}</TitleText>
                        <AnswerText>{Goal_CustomCalories}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditActivityLevelModal', true)}>
                        <TitleText>{t('MealPlan_Activity_level')}</TitleText>
                        <AnswerText>{ActivityLevel}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditHeightModal', true)}>
                        <TitleText>{t('height')}</TitleText>
                        <AnswerText>{HeightString}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditWeightModal', true)}>
                        <TitleText>{t('Weight')}</TitleText>
                        <AnswerText>{WeightString}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditNumberOfMealsModal', true)}>
                        <TitleText>{t('MealPlan_Meals_In_A_Day')}</TitleText>
                        <AnswerText>{NumberOfMeals}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditDietaryRestrictionsModal', true)}>
                        <TitleText>{t('WorkoutPlanSettings_Allergies_Restrictions_Header')}</TitleText>
                        <AnswerText>{Allergies_Restrictions || t('none_with_first_char_uppercase')}</AnswerText>
                    </InformationContainer>
                </QuestionsContainer>
            </>
        );
    }

    renderMediaPreview = ({ Document: { Id: DocumentId, FileExtension, FileName, FileSize_KB, IsNewMedia, MediaUrl, NewlySaved } }) => {
        FileExtension = FileExtension || getFileExtension(MediaUrl);
        IsNewMedia = IsNewMedia || false;

        var DeleteMediaIndex = this.state.DeleteMedia.indexOf(DocumentId);
        var IsDeletedSelected = DeleteMediaIndex !== -1;

        switch (FileExtension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                if (NewlySaved) {
                    return (
                        <MediaPreview key={MediaUrl}>
                            <StyledMediaPreview ImageSrc={MediaUrl} IsDeletedSelected={IsDeletedSelected} NewlySaved={NewlySaved} OnClick={() => this.onToggleShowFullscreenMediaPreview(null, MediaUrl, true)} SelfLoading />
                        </MediaPreview>
                    );
                }

                return (
                    <MediaPreview key={MediaUrl}>
                        <StyledMediaPreview ImageSrc={MediaUrl} IsDeletedSelected={IsDeletedSelected} IsNewMedia={IsNewMedia} OnClick={() => this.onToggleShowFullscreenMediaPreview(null, MediaUrl, true)} SelfLoading />
                        <MultiSelect onClick={() => this.onDeleteMedia({ DeleteMediaIndex, DocumentId, FileSize_KB, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} />
                    </MediaPreview>
                );

            case 'mov':
            case 'mp4':
                if (NewlySaved) {
                    return (
                        <MediaPreview key={MediaUrl}>
                            <StyledVideoPreview IsDeletedSelected={IsDeletedSelected} NewlySaved={NewlySaved} autoPlay={false} controls={false} height="100%" width="100%" onClick={() => this.onToggleShowFullscreenMediaPreview(null, MediaUrl, true, 'video')} src={MediaUrl} />
                        </MediaPreview>
                    );
                }

                return (
                    <MediaPreview key={MediaUrl}>
                        <StyledVideoPreview IsDeletedSelected={IsDeletedSelected} IsNewMedia={IsNewMedia} autoPlay={false} controls={false} height="100%" width="100%" onClick={() => this.onToggleShowFullscreenMediaPreview(null, MediaUrl, true, 'video')} src={MediaUrl} />
                        <MultiSelect onClick={() => this.onDeleteMedia({ DeleteMediaIndex, DocumentId, FileSize_KB, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} />
                    </MediaPreview>
                );

            case 'pdf':
                if (NewlySaved) return <StyledMediaDocumentPreview key={MediaUrl} IsDeletedSelected={IsDeletedSelected} NewlySaved={NewlySaved}><ButtonIcon src={PDF} alt="Button icon" /><PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" /></StyledMediaDocumentPreview>
                if (IsNewMedia) return <StyledMediaDocumentPreview key={MediaUrl} IsDeletedSelected={IsDeletedSelected} IsNewMedia={IsNewMedia}><ButtonIcon src={PDF} alt="Button icon" /><PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" /><MultiSelect onClick={event => this.onDeleteMedia({ event, DeleteMediaIndex, DocumentId, FileSize_KB, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} /></StyledMediaDocumentPreview>
                return (
                    <StyledExternalLink key={MediaUrl} href={MediaUrl} target="_new">
                        <StyledMediaDocumentPreview IsDeletedSelected={IsDeletedSelected} IsNewMedia={IsNewMedia}>
                            <ButtonIcon src={PDF} alt="Button icon" />
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" />
                            <MultiSelect onClick={event => this.onDeleteMedia({ event, DeleteMediaIndex, DocumentId, FileSize_KB, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} />
                        </StyledMediaDocumentPreview>
                    </StyledExternalLink>
                );

            default:
                return <StyledMediaDocumentPreview key={MediaUrl} IsDeletedSelected={IsDeletedSelected} IsNewMedia={IsNewMedia}><PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" /><MultiSelect onClick={event => this.onDeleteMedia({ event, DeleteMediaIndex, DocumentId, FileSize_KB, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} /></StyledMediaDocumentPreview>
        }
    }

    renderPreviewFullscreenMedia = () => {
        var { PreviewMedia, PreviewMediaFileType, PreviewMediaUrl, ShowFullscreenMediaPreview } = this.state;

        if (PreviewMediaUrl && ShowFullscreenMediaPreview) {
            return (
                <PreviewMediaModal
                    FileType={PreviewMediaFileType}
                    Media={PreviewMedia}
                    MediaUrl={PreviewMediaUrl}
                    OnHide={() => this.onToggleShowFullscreenMediaPreview(null, null, false)}
                    Show={ShowFullscreenMediaPreview}
                />
            );
        }
    }

    renderUnitInput = (StateName, ReadOnly) => {
        if (StateName === 'Height' && this.state.GetStartedQuestionAnswers.HeightUnit === 'FT') {
            var { Height_Feet, Height_Inches } = this.state.GetStartedQuestionAnswers;

            return (
                <UnitInputContainer>
                    <HeightInput
                        FontFamily="semibold"
                        FontSize="extra-large-2"
                        NoLabel
                        NoMargin
                        OnChange={e => this.onChangeUnitInput(e, 'Height_Feet')}
                        Placeholder="0"
                        Size="auto"
                        Type="number"
                        Value={`${Height_Feet}`}
                    />
                    <HeightFeetIcon ElementType="span" FontSize="extra-extra-large" Text="'" />
                    <HeightInput
                        FontFamily="semibold"
                        FontSize="extra-large-2"
                        NoLabel
                        NoMargin
                        OnChange={e => this.onChangeUnitInput(e, 'Height_Inches')}
                        Placeholder="0"
                        Size="auto"
                        Type="number"
                        Value={`${Height_Inches}`}
                    />
                    <HeightFeetIcon ElementType="span" FontSize="extra-extra-large" Text={`"`} />
                </UnitInputContainer>
            );
        }

        return (
            <UnitInputContainer>
                <UnitInput
                    FontFamily="semibold"
                    FontSize="extra-large-2"
                    NoLabel
                    NoMargin
                    OnChange={e => this.onChangeUnitInput(e, StateName)}
                    Placeholder="0"
                    ReadOnly={ReadOnly}
                    Size="auto"
                    Type="number"
                    Value={`${this.state.GetStartedQuestionAnswers[StateName]}`}
                />
            </UnitInputContainer>
        );
    }

    renderUnitSelections = (StateName, Unit1, Unit2) => {
        var SelectedUnit = this.state.GetStartedQuestionAnswers[StateName] === Unit1 ? 'Unit1' : 'Unit2';

        return (
            <MultiUnitContainer>
                <MultiUnit onClick={() => this.onChangeUnitSelection(StateName, Unit1)} Selected={SelectedUnit === 'Unit1'}>
                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={Unit1} />
                </MultiUnit>
                <MultiUnit onClick={() => this.onChangeUnitSelection(StateName, Unit2)} Selected={SelectedUnit === 'Unit2'}>
                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={Unit2} />
                </MultiUnit>
            </MultiUnitContainer>
        );
    }

    renderUploadNoteMedia = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { DeleteMedia, MaxFileSizesKB, MediaTooLarge, SelectedMedia, TrainerClientMedia } = this.state;

        var Documents = [ ...TrainerClientMedia.Documents, ...SelectedMedia ]

        var Disabled = false;
        if (SelectedMedia.length === 0 && DeleteMedia.length === 0) Disabled = true;
        
        var Buttons = [
            { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg,video/mov,video/mp4,.mov,.mp4,application/pdf,.pdf", id: "NoteMedia", multiple: "multiple", type: "file", onChange: event => this.onSelectNoteMedia({ event }) }, Title: t('Upload') },
            { BackgroundColor: Disabled ? 'gray' : 'blue-astronaut', BackgroundColorHover: Disabled ? 'gray-hover' : 'blue-astronaut-hover', Color: Disabled ? 'black' : 'white', ColorHover: Disabled ? 'black' : 'white', FontFamily: 'semibold', OnClick: Disabled ? null : this.onUploadNoteMedia, Title: t('save_with_first_char_uppercase') }
        ];

        MaxFileSizesKB = MaxFileSizesKB / 1024;

        return (
            <UploadContainer>
                <UploadButtonsRow>
                    <UploadButtonsContainer
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </UploadButtonsRow>
                
                <PageText FontFamily="medium" FontSize="medium-1" Text={`${(Math.round(((this.state.TrainerClientMedia.FileSize) / 1024) * 100) / 100)}MB / ${MaxFileSizesKB}MB ${t('used')}`} />
                
                {
                    !!+MediaTooLarge &&
                    <PageText
                        ContainerWidth="100%"
                        FontColor="red-bittersweet"
                        FontFamily="medium-italic"
                        FontSize="medium-1"
                        JustifyContent="center"
                        Text={t('file_too_large', { MaxUploadSize: `${MaxFileSizesKB}MB`, UploadedSize: `${(Math.round(((this.state.MediaTooLarge) / 1024) * 100) / 100)}MB` })}
                        TextAlign="center"
                    />
                }
                {
                    Documents.length > 0 &&
                    <UploadDocumentsContainer>
                        {Documents.map(Document => this.renderMediaPreview({ Document }))}
                    </UploadDocumentsContainer>
                }
            </UploadContainer>
        );
    }

    renderWorkoutQuestions = () => {
        var { t } = this.props;
        var { GetStartedQuestionAnswers: { ExperienceLevel_Endurance, ExperienceLevel_Strength, InjuriesString, MainGym, WeightString } } = this.state;

        return (
            <>
                <QuestionsContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditWeightModal', true)}>
                        <TitleText>{t('Weight')}</TitleText>
                        <AnswerText>{WeightString}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditMainGymModal', true)}>
                        <TitleText>{t('Main_gym')}</TitleText>
                        <AnswerText>{MainGym}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditExperienceLevel_StrengthModal', true)}>
                        <TitleText>{t('WorkoutPlan_Strength_Experience_Level_title')}</TitleText>
                        <AnswerText>{ExperienceLevel_Strength}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditExperienceLevel_EnduranceModal', true)}>
                        <TitleText>{t('WorkoutPlan_Endurance_Experience_Level_title')}</TitleText>
                        <AnswerText>{ExperienceLevel_Endurance}</AnswerText>
                    </InformationContainer>
                    <InformationContainer onClick={() => this.onToggleShowEditClientModal('ShowEditInjuriesModal', true)}>
                        <TitleText>{t('WorkoutPlan_injuries')}</TitleText>
                        <AnswerText>{InjuriesString || t('none_with_first_char_uppercase')}</AnswerText>
                    </InformationContainer>
                </QuestionsContainer>
            </>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetClientDetails) return <Loading />;

        var { t } = this.props;
        var { ClientDetails, Device, TryingAddGetStartedMeals, TryingAddGetStartedWorkouts, TryingInsertEditTrainerClientMedia } = this.props;
        var { ClientId } = this.props.match.params;
        var { NeedsRefresh_Meals, NeedsRefresh_Tasks, NeedsRefresh_Workouts, SelectedTab, ShowJournal } = this.state;

        if (Object.entries(ClientDetails).length === 0 || ClientDetails.constructor !== Object) return null;

        var DisplayName = ClientDetails.DisplayName;

        return (
            <>
                {(TryingAddGetStartedMeals || TryingAddGetStartedWorkouts || TryingInsertEditTrainerClientMedia) && <Loading />}

                <Page NoMargin>
                    <TopArea className="TopArea">
                        <TopAreaTop className="TopAreaTop">
                            {
                                (Device === 'ipad' || Device === 'laptop') ?
                                <>
                                    <TopAreaItem1Parent className="TopAreaItem1Parent">
                                        <TopAreaItem1 className="TopAreaItem1">
                                            <ProfilePhotoContainer className="ProfilePhotoContainer">
                                                <ProfilePhoto Sex={ClientDetails.Sex || 'Male'} Size="small-3" Source={ClientDetails.UserPhoto || ''} />
                                            </ProfilePhotoContainer>

                                            <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                                        </TopAreaItem1>

                                        {
                                            !!+this.props.ClientDetails.IsAbelClient &&
                                            <>
                                                <MultiText
                                                    Texts={[
                                                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('product')}:` },
                                                        { FontFamily: 'medium', FontSize: 'medium-1', Text: this.props.ClientDetails.ProductName }
                                                    ]}
                                                />
                                                <MultiText
                                                    Texts={[
                                                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: `Next meeting with company:` },
                                                        { FontFamily: 'medium', FontSize: 'medium-1', Text: this.props.ClientDetails.StatusMeetingDate }
                                                    ]}
                                                />
                                            </>
                                        }
                                    </TopAreaItem1Parent>

                                    <TopAreaItem2 className="TopAreaItem2">
                                        <ButtonGroup
                                            Buttons={[
                                                { OnClick: () => this.onSelectTab('Meals'), Selected: this.state.SelectedTab === 'Meals', Title: t('ptadmin_contenttype_header_meals') },
                                                { OnClick: () => this.onSelectTab('Workouts'), Selected: this.state.SelectedTab === 'Workouts', Title: t('ptadmin_contenttype_header_workouts') },
                                                { OnClick: () => this.onSelectTab('Progress'), Selected: this.state.SelectedTab === 'Progress', Title: t('ptadmin_contenttype_header_progress') },
                                                { OnClick: () => this.onSelectTab('Tasks'), Selected: this.state.SelectedTab === 'Tasks', Title: t('ptadmin_contenttype_header_tasks_statuses') },
                                                { OnClick: () => this.onSelectTab('Journal'), Selected: this.state.SelectedTab === 'Journal', Title: t('ptadmin_contenttype_header_journal') }
                                            ]}
                                        />
                                    </TopAreaItem2>

                                    {/* <TopAreaItem3 className="TopAreaItem3">
                                        <JournalButton className="JournalButton" onClick={this.onToggleShowJournal}>
                                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('notes')} TextAlign="left" />

                                            <JournalIcon>
                                                <img src={ShowJournal ? UpArrow : DownArrow} alt="Open / Close Journal" />
                                            </JournalIcon>
                                        </JournalButton>
                                    </TopAreaItem3> */}
                                </>
                            :
                                <>
                                    <MobileTop className="MobileTop">
                                        <TopAreaItem1Parent className="TopAreaItem1Parent">
                                            <TopAreaItem1 className="TopAreaItem1">
                                                <ProfilePhotoContainer className="ProfilePhotoContainer">
                                                    <ProfilePhoto Sex={ClientDetails.Sex || 'Male'} Size="small-3" Source={ClientDetails.UserPhoto || ''} />
                                                </ProfilePhotoContainer>

                                                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                                            </TopAreaItem1>

                                            <MultiText
                                                Texts={[
                                                    { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('sales_frequency')}:` },
                                                    { FontFamily: 'medium', FontSize: 'medium-1', Text: this.props.ClientDetails.Frequency ? `${this.props.ClientDetails.Frequency}` : 'N/A' }
                                                ]}
                                            />
                                        </TopAreaItem1Parent>

                                        {/* <TopAreaItem3 className="TopAreaItem3">
                                            <JournalButton className="JournalButton" onClick={this.onToggleShowJournal}>
                                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('notes')} TextAlign="left" />

                                                <JournalIcon>
                                                    <img src={ShowJournal ? UpArrow : DownArrow} alt="Open / Close Journal" />
                                                </JournalIcon>
                                            </JournalButton>
                                        </TopAreaItem3> */}
                                    </MobileTop>

                                    <Spacer Size="extra-small" />

                                    <MobileBottom className="MobileBottom">
                                        <TopAreaItem2 className="TopAreaItem2">
                                            <ButtonGroup
                                                Buttons={[
                                                    { OnClick: () => this.onSelectTab('Meals'), Selected: this.state.SelectedTab === 'Meals', Title: t('ptadmin_contenttype_header_meals') },
                                                    { OnClick: () => this.onSelectTab('Workouts'), Selected: this.state.SelectedTab === 'Workouts', Title: t('ptadmin_contenttype_header_workouts') },
                                                    { OnClick: () => this.onSelectTab('Progress'), Selected: this.state.SelectedTab === 'Progress', Title: t('ptadmin_contenttype_header_progress') },
                                                    { OnClick: () => this.onSelectTab('Tasks'), Selected: this.state.SelectedTab === 'Tasks', Title: t('ptadmin_contenttype_header_tasks_statuses') },
                                                    { OnClick: () => this.onSelectTab('Journal'), Selected: this.state.SelectedTab === 'Journal', Title: t('ptadmin_contenttype_header_journal') }
                                                ]}
                                                OwnRows
                                            />
                                        </TopAreaItem2>
                                    </MobileBottom>
                                </>
                            }
                        </TopAreaTop>

                        {ShowJournal && this.renderJournal()}
                    </TopArea>

                    <Spacer Size="small" />

                    <StyledHR />

                    <Spacer Size="small" />

                    {(SelectedTab === 'Workouts' || SelectedTab === 'Meals') &&
                        <>
                            {this.renderAdditionalInformation()}

                            <Spacer Size="small" />
                        </>
                    }

                    {
                        SelectedTab === 'Meals' ?
                        <ClientMeals
                            ClientId={ClientId}
                            GetStartedCompleted={!!ClientDetails.GetStartedCompleted_Meals}
                            NeedsRefresh={NeedsRefresh_Meals}
                            StartingDate={this.props.location.state && this.props.location.state.MealDate}
                        />
                    :
                        SelectedTab === 'Workouts' ?
                        <ClientWorkouts
                            ClientId={ClientId}
                            ForceGetStarted={this.props.location && this.props.location.state && this.props.location.state.ForceGetStarted}
                            NeedsRefresh={NeedsRefresh_Workouts}
                            StartingDate={this.props.location.state && this.props.location.state.WorkoutDate}
                        />
                    :
                        SelectedTab === 'Progress' ? 
                        <ClientProgress ClientId={ClientId} />
                    :
                        SelectedTab === 'Tasks' ?
                        <ClientTasksStatuses
                            ClientId={ClientId}
                            NeedsRefresh={NeedsRefresh_Tasks}
                            StartingDate={this.props.location.state && this.props.location.state.TasksDate}
                        />
                    :
                        SelectedTab === 'Journal' ?
                        <ClientJournal ClientId={ClientId} />
                    :
                        null
                    }
                </Page>

                {this.renderChooseGymModal()}
                {this.renderEditActivityLevelModal()}
                {this.renderEditDietaryRestrictionsModal()}
                {this.renderEditExperienceLevel_EnduranceModal()}
                {this.renderEditExperienceLevel_StrengthModal()}
                {this.renderEditGoal_CustomCaloriesModal()}
                {this.renderEditHeightModal()}
                {this.renderEditInjuriesModal()}
                {this.renderEditMainGymModal()}
                {this.renderEditNumberOfMealsModal()}
                {this.renderEditWeightModal()}
                {this.renderPreviewFullscreenMedia()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        
        ActivityLevels: state.Client.ActivityLevels,
        Allergies: state.Client.ClientMealSettings.Allergies,
        ClientDetails: state.Client.ClientDetails,
        DietTypes: state.Client.ClientMealSettings.DietTypes,
        ExperienceLevels: state.Client.ExperienceLevels,
        Goals: state.Client.Goals,
        Joints: state.Client.Joints,
        KeepCurrentClient: state.Client.KeepCurrentClient,
        Muscles: state.Client.Muscles,
        RetrievedActivityLevels: state.Client.RetrievedActivityLevels,
        RetrievedDietaryRestrictions: state.Client.RetrievedDietaryRestrictions,
        RetrievedExperienceLevels: state.Client.RetrievedExperienceLevels,
        RetrievedGoals: state.Client.RetrievedGoals,
        RetrievedInjuries: state.Client.RetrievedInjuries,
        SelectedTab: state.Client.SelectedTab,
        WeeklyView_EndDate: state.Client.WeeklyView_Workouts_EndDate,
        WeeklyView_StartDate: state.Client.WeeklyView_Workouts_StartDate,

        ClientMeals: state.Meals.ClientMeals,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingAddGetStartedMeals: state.Client.TryingAddGetStartedMeals,
        TryingAddGetStartedMealsError: state.Client.TryingAddGetStartedMealsError,
        TryingAddGetStartedWorkouts: state.Client.TryingAddGetStartedWorkouts,
        TryingAddGetStartedWorkoutsError: state.Client.TryingAddGetStartedWorkoutsError,
        TryingGetActivityLevels: state.Client.TryingGetActivityLevels,
        TryingGetActivityLevelsError: state.Client.TryingGetActivityLevelsError,
        TryingGetClientDetails: state.Client.TryingGetClientDetails,
        TryingGetClientDetailsError: state.Client.TryingGetClientDetailsError,
        TryingGetClientMealSettings: state.Client.TryingGetClientMealSettings,
        TryingGetClientMealSettingsError: state.Client.TryingGetClientMealSettingsError,
        TryingGetExperienceLevels: state.Client.TryingGetExperienceLevels,
        TryingGetExperienceLevelsError: state.Client.TryingGetExperienceLevelsError,
        TryingGetGoals: state.Client.TryingGetGoals,
        TryingGetGoalsError: state.Client.TryingGetGoalsError,
        TryingGetInjuries: state.Client.TryingGetInjuries,
        TryingGetInjuriesError: state.Client.TryingGetInjuriesError,
        TryingInsertEditTrainerClientMedia: state.Client.TryingInsertEditTrainerClientMedia,
        TryingInsertEditTrainerClientMediaError: state.Client.TryingInsertEditTrainerClientMediaError,
        TryingUpdateTrainerClientNote: state.Client.TryingUpdateTrainerClientNote,
        TryingUpdateTrainerClientNoteError: state.Client.TryingUpdateTrainerClientNoteError
    };
};
  
export default withTranslation()(connect(mapStateToProps, { AddGetStartedMeals, AddGetStartedWorkouts, ClearClientDetails, ClearGetStarted, GetClientDetails, GetClientMealSettings, InsertEditTrainerClientMedia, ViewActivityLevels, ViewExperienceLevels, ViewGoals, ViewInjuries, UpdateTrainerClientNote, UploadToPresignedUrl } )(ClientDetail));