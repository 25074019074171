import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddCompanyEmployees, DeleteCompanyEmployees, DownloadCompanyEmployeeData, DownloadCompanyEmployees, GetCompanies, GetCompany, GetOrderProducts, GetOrgMembers, InsertEditCompany, InsertEditOrder, MakeEmployeeUpdate, MarkOrderAsEnded, UpdateCompanyEmployees } from '../../Services/Actions';
import Select, { components } from 'react-select';

import moment from 'moment';

import { COMPANY_INVITE_URL } from '../../Config';

import * as Styles from './styles';

import { toast } from 'react-toastify';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import { emailValidator, SortValues } from '../../Functions';

import Info from '../../Modals/Info';

import Copy from '../../Assets/Icons/Copy_White.png';
import DownloadIcon from '../../Assets/Icons/Download_Gray.png';
import EditIcon from '../../Assets/Icons/Swap_White.png';
import MoreDots from '../../Assets/Icons/MoreDots.png';
import Plus from '../../Assets/Icons/PlusSign_White.png';
import RedX from '../../Assets/Icons/X_Red.png';

const DisplayTimeInUTC = inputDate => {
    return moment(inputDate).format('[NumbersDate]');
};

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, backgroundColor: 'var(--white-concrete)', border: 'none', cursor: isDisabled ? 'not-allowed' : 'default', height: '45px', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

const ValueContainer = ({ selectProps, children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
        values = selectProps.value.map((x) => x.DisplayName).join(', ');
    }

    return (
      <components.ValueContainer {...props}>
        <div
            style={{
                maxWidth: "80%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginBottom: '0px'
            }}
        >
            {values}
        </div>
        {input}
      </components.ValueContainer>
    );
};

const ValueContainer_Products = ({ selectProps, children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
        values = selectProps.value.map((x) => x.ProductName).join(', ');
    }

    return (
      <components.ValueContainer {...props}>
        <div
            style={{
                maxWidth: "80%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginBottom: '0px'
            }}
        >
            {values}
        </div>
        {input}
      </components.ValueContainer>
    );
};

const DEFAULT_SORTING = {
    PendingEmployees: {
        Column: 'RegisteredDate',
        Direction: 'ASC'
    },
    NotStartedEmployees: {
        Column: 'NotStartedDate',
        Direction: 'ASC'
    },
    NotReachedEmployees: {
        Column: 'NotReachedDate',
        Direction: 'ASC'
    },
    ActiveEmployees: {
        Column: 'StartDate',
        Direction: 'ASC'
    },
    SwitchedEmployees: {
        Column: 'SwitchedDate',
        Direction: 'ASC'
    },
    QuitPhase1Employees: {
        Column: 'QuitDate',
        Direction: 'ASC'
    },
    CompanyEndedEmployees: {
        Column: 'EndedDate',
        Direction: ''
    },
    QuitPhase1PlusEmployees: {
        Column: 'QuitDate',
        Direction: 'ASC'
    }
}

const DEFAULT_EMPLOYEE_OPTIONS_POPOVER = { OptionType: null, SelectedDHC: null };
const DEFAULT_INSERT_EDIT_COMPANY = { ActiveEmployees: [], ActiveTab: 'Employees', CompanyId: null, CompanyEndedEmployees: [], CompanyName: '', HasWhitelabeling: false, NotReachedEmployees: [], NotStartedEmployees: [], PendingEmployees: [], QuitPhase1Employees: [], QuitPhase1PlusEmployees: [], Orders: [], SwitchedEmployees: [] };
const DEFAULT_INSERT_EDIT_ORDER = { AgreementDate: '', CompanyId: null, DeliveryDuration: '', DeliveryStartDate: '', InviteLink: '', OrderId: null, Products: [], RecruitingStartDate: '', RecruitingEndDate: '', StatusMeetingDate: '' };
const EMPLOYEE_OPTIONS_ARRAY = [{ value: 'Switch', label: 'Switch' }, { value: 'CancelSwitch', label: 'Cancel Switch' }, { value: 'Quit', label: 'Quit' }, { value: 'CancelQuit', label: 'Cancel Quit' }, { value: 'NotReached', label: 'Not Reached' }, { value: 'CompanyEnded', label: 'Company ended' }];

const PENDING_EMPLOYEES_COLUMNS = [
    { id: 1, value: 'FullName', label: 'Employee' },
    { id: 2, value: 'PhoneNumber', label: 'Phone number' },
    { id: 3, value: 'ChosenDHC', label: 'ChosenDHC' },
    { id: 4, value: 'AlgoDHC', label: 'Algorithm DHC' },
    { id: 5, value: 'RegisteredDate', label: 'Registered date' }
];

const NOTSTARTED_EMPLOYEES_COLUMNS = [
    { id: 1, value: 'FullName', label: 'Employee' },
    { id: 2, value: 'PhoneNumber', label: 'Phone number' },
    { id: 3, value: 'DHC', label: 'DHC' },
    { id: 4, value: 'NotStartedDate', label: 'Not started date' }
];

const NOTREACHED_EMPLOYEES_COLUMNS = [
    { id: 1, value: 'FullName', label: 'Employee' },
    { id: 2, value: 'PhoneNumber', label: 'Phone number' },
    { id: 3, value: 'DHC', label: 'DHC' },
    { id: 4, value: 'NotReachedDate', label: 'Not reached date' }
];

const ACTIVE_EMPLOYEES_COLUMNS = [
    { id: 1, value: 'Employee', label: 'Employee' },
    { id: 2, value: 'PhoneNumber', label: 'Phone number' },
    { id: 3, value: 'DHC', label: 'DHC' },
    { id: 4, value: 'StartDate', label: 'Start date' },
    { id: 5, value: 'EndDate', label: 'End date' },
    { id: 6, value: 'Product', label: 'Product' },
    { id: 7, value: 'Satisfaction', label: 'Satisfaction' },
    { id: 8, value: 'Activities', label: 'Tasks / sessions' },
    { id: 9, value: 'LastVideoCall', label: 'Last video call' },
    { id: 10, value: 'WantsToSwitch', label: 'Switch request' },
    { id: 11, value: 'WantsToQuit', label: 'Wants to quit' }
];

const SWITCHED_EMPLOYEES_COLUMNS = [
    { id: 1, value: 'FullName', label: 'Employee' },
    { id: 2, value: 'PhoneNumber', label: 'Phone number' },
    { id: 3, value: 'From_TrainerFullName', label: 'Original DHC' },
    { id: 4, value: 'To_TrainerFullName', label: 'New DHC' },
    { id: 5, value: 'SwitchedDate', label: 'Switched date' }
];

const QUITPHASE1_EMPLOYEES_COLUMNS = [
    { id: 1, value: 'FullName', label: 'Employee' },
    { id: 2, value: 'PhoneNumber', label: 'Phone number' },
    { id: 3, value: 'TrainerFullName', label: 'DHC' },
    { id: 4, value: 'QuitDate', label: 'Quit date' }
];

const COMPANYENDED_EMPLOYEES_COLUMNS = [
    { id: 1, value: 'FullName', label: 'Employee' },
    { id: 2, value: 'PhoneNumber', label: 'Phone number' },
    { id: 3, value: 'EndedDate', label: 'Ended date' }
];

const QUITPHASE1PLUS_EMPLOYEES_COLUMNS = [
    { id: 1, value: 'FullName', label: 'Employee' },
    { id: 2, value: 'PhoneNumber', label: 'Phone number' },
    { id: 3, value: 'TrainerFullName', label: 'DHC' },
    { id: 4, value: 'QuitDate', label: 'Quit date' }
];

class CompaniesComponent extends React.Component {
    _isMounted = false;

    state = {
        Companies: [],
        InsertEditCompany: {},
        ShowAddEmployeesModal: false,
        ShowAddEmployeesModal_CompanyId: null,
        ShowAddEmployeesModal_CompanyName: '',
        ShowAddEmployeesModal_Emails: [],
        ShowAddEmployeesModal_TrainerEmail: '',
        ShowInsertEditCompanyModal: false,
        ShowInsertEditCompanyModal_EmployeeType: 'Active',

        ShowEmployeeOptionsModal: false,
        ShowEmployeeOptionsModal_Data: {},
        EmployeeUpdateOptions: { ...DEFAULT_EMPLOYEE_OPTIONS_POPOVER },
        
        InsertEditOrder: {},
        ShowInsertEditOrder: false,
        
        SearchCompanyName: '',

        Filters: {
            OrgMembers: {
                HasLoaded: false,
                IsLoading: false,
                OrgMembers: [],

                Selected: []
            },
            Products: {
                HasLoaded: false,
                IsLoading: false,
                Products: [],

                Selected: []
            }
        },
        Sorting: { ...DEFAULT_SORTING }
    }

    componentDidMount() {
        this._isMounted = true;

        this.onGetCompanies();
    }

    onAddCompanyEmployees = () => {
        var { ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_Emails: Emails, ShowAddEmployeesModal_TrainerEmail: TrainerEmail } = this.state;

        Emails = Emails.filter(({ Valid }) => !!Valid).map(({ Email }) => Email).join(';');

        if (Emails.length) {
            this.props.AddCompanyEmployees({ CompanyId, Emails, TrainerEmail }).then(({ Company }) => {
                if (Company) {
                    this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: false });
                    if (+CompanyId === +this.state.InsertEditCompany.CompanyId) this.setState({ InsertEditCompany: Company });
                }
            })
        }
    }

    onAddEmail = () => {
        this.setState({ ShowAddEmployeesModal_Emails: [ ...this.state.ShowAddEmployeesModal_Emails, { Email: '', Valid: false } ] });
    }

    onBlurCompanyName = () => {
        this.onInsertEditCompany();
    }

    onBlurEmail = EmailIndex => {
        var ShowAddEmployeesModal_Emails = [ ...this.state.ShowAddEmployeesModal_Emails ];
        ShowAddEmployeesModal_Emails[EmailIndex].Valid = !!emailValidator(ShowAddEmployeesModal_Emails[EmailIndex].Email);

        this.setState({ ShowAddEmployeesModal_Emails });
    }

    onChangeAddEmployees_Company = ({ CompanyId, CompanyName }) => {
        this.setState({ ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_CompanyName: CompanyName });
    }

    onChangeCompanyName = event => {
        var InsertEditCompany = { ...this.state.InsertEditCompany };
        InsertEditCompany.CompanyName = event.target.value;

        this.setState({ InsertEditCompany });
    }

    onChangeCustomProductPrice = ({ event, index }) => {
        // Add to InsertEditOrder data
            var InsertEditOrder = { ...this.state.InsertEditOrder };
            InsertEditOrder.Products[index].Price = event.target.value;

        this.setState({ InsertEditOrder });
    }

    onChangeEmail = ({ event, EmailIndex }) => {
        var ShowAddEmployeesModal_Emails = [ ...this.state.ShowAddEmployeesModal_Emails ];
        ShowAddEmployeesModal_Emails[EmailIndex] = { Email: event.target.value, Valid: true };

        this.setState({ ShowAddEmployeesModal_Emails });
    }

    onChangeEmployeeOptionsModal_OptionType = OptionType => {
        this.setState({ EmployeeUpdateOptions: { ...this.state.EmployeeUpdateOptions, OptionType } });
    }

    onChangeFilter_DHCs = data => {
        var Filters = { ...this.state.Filters };
        Filters.OrgMembers = { ...this.state.Filters.OrgMembers };
        Filters.OrgMembers.Selected = data;

        this.setState({ Filters }, () => this.onGetCompanies());
    }

    onChangeFilter_DHCs = data => {
        var Filters = { ...this.state.Filters };
        Filters.OrgMembers = { ...this.state.Filters.OrgMembers };
        Filters.OrgMembers.Selected = data;

        this.setState({ Filters }, () => this.onGetCompanies());
    }

    onChangeFilter_DHCs_EmployeeOptions = SelectedDHC => {
        this.setState({ EmployeeUpdateOptions: { ...this.state.EmployeeUpdateOptions, SelectedDHC } })
    }

    onChangeFilter_Products = data => {
        if (!data) data = [];

        // Add to selected filters
            var Filters = { ...this.state.Filters };
            Filters.Products = { ...this.state.Filters.Products };
            Filters.Products.Selected = data;

            var SelectedProductIds = data.map(({ ProductId }) => +ProductId);

        // Add to InsertEditOrder data
            var InsertEditOrder = { ...this.state.InsertEditOrder };

            var NewProducts = [];
            var NewProducts_Index = [];
            for (var i = 0; i < InsertEditOrder.Products.length; i++) {
                var { ProductId } = InsertEditOrder.Products[i];

                // Only Keep Currently Selected Products If They Are Still Selected
                    if (SelectedProductIds.indexOf(+ProductId) !== -1) {
                        NewProducts.push({ ...InsertEditOrder.Products[i] });
                        NewProducts_Index.push(+ProductId);
                    }
            }

            for (i = 0; i < data.length; i++) {
                if (NewProducts_Index.indexOf(+data[i].ProductId) === -1) {
                    NewProducts.push({ ...data[i], Price: '' });
                    NewProducts_Index.push(+data[i].ProductId);
                }
            }

            InsertEditOrder.Products = NewProducts;

        this.setState({ Filters, InsertEditOrder });
    }

    onChangeOrderInput = ({ event, type }) => {
        this.setState({ InsertEditOrder: { ...this.state.InsertEditOrder, [type]: event.target.value } });
    }

    onChangeSearchCompanyName = event => {
        this.setState({ SearchCompanyName: event.target.value });
    }

    onChangeTrainerEmail = event => {
        this.setState({ ShowAddEmployeesModal_TrainerEmail: event.target.value });
    }

    onCopyInviteLink = ({ event, InviteLinkUrl }) => {
        event.preventDefault();
        event.stopPropagation();

        navigator.clipboard.writeText(InviteLinkUrl);

        toast.success(this.props.t('copied'), {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onDeleteEmail = EmailIndex => {
        this.setState({ ShowAddEmployeesModal_Emails: [ ...this.state.ShowAddEmployeesModal_Emails.slice(0, EmailIndex), ...this.state.ShowAddEmployeesModal_Emails.slice(EmailIndex + 1) ] });
    }

    onDownloadEmployeesData = ({ CompanyIds = '1' }) => {
        this.props.DownloadCompanyEmployeeData({ CompanyIds });

        toast.success('Processing started. Email will be sent upon completion', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onDownloadEmployees = ({ CompanyId = 0, EmployeeType }) => {
        this.props.DownloadCompanyEmployees({ CompanyId, EmployeeType });
    }

    onFocusFilter_DHCs = () => {
        if (!this.state.Filters.OrgMembers.HasLoaded) this.onLoadDHCs();
    }

    onFocusFilter_Products = () => {
        if (!this.state.Filters.Products.HasLoaded) {
            var Filters = { ...this.state.Filters };
            var Products_Filter = { ...Filters.Products };
            Products_Filter.IsLoading = true;

            Filters.Products = Products_Filter;

            this.setState({ Filters }, () => {
                this.props.GetOrderProducts().then(({ Products }) => {
                    Products_Filter.HasLoaded = true;
                    Products_Filter.IsLoading = false;
                    Products_Filter.Products = Products;

                    Filters.Products = Products_Filter;

                    this.setState({ Filters });
                })
            });
        }
    }

    onGetCompanies = () => {
        var CompanyName = this.state.SearchCompanyName;
        var DHCIds = this.state.Filters.OrgMembers.Selected.map(({ UserId }) => UserId).join(',');

        var PageNo = 1;
        var PageSize = 1000;
        var SortCol = 'CompanyName';
        var SortDir = 'ASC';

        this.props.GetCompanies({ CompanyName, DHCIds, PageNo, PageSize, SortCol, SortDir }).then(({ Companies }) => {
            this.setState({ Companies, Sorting: { ...DEFAULT_SORTING } });
        });
    }

    onInsertEditCompany = () => {
        var { CompanyId, CompanyName } = this.state.InsertEditCompany;

        this.props.InsertEditCompany({ CompanyId, CompanyName }).then(() => {
            // this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: false });
            this.onGetCompanies();
        });
    }

    onInsertEditOrder = () => {
        var { CompanyId, CompanyName } = this.state.InsertEditCompany;
        var { Products, ...rest } = this.state.InsertEditOrder;

        Products = Products.filter(({ Price }) => !!Price).map(({ ProductId, Price }) => `${ProductId},${Price}`).join(';');

        this.props.InsertEditOrder({ Products, ...rest, CompanyId, CompanyName }).then(() => {
            this.onToggleShowInsertEditOrder({ ShowInsertEditOrder: false });
            this.props.GetCompany({ CompanyId }).then(({ Company }) => {
                this.setState({ InsertEditCompany: { ...this.state.InsertEditCompany, ...Company } });
            });
        })
    }

    onLoadDHCs = () => {
        var Filters = { ...this.state.Filters };
        var OrgMembers_Filter = { ...Filters.OrgMembers };
        OrgMembers_Filter.IsLoading = true;

        Filters.OrgMembers = OrgMembers_Filter;

        this.setState({ Filters }, () => {
            this.props.GetOrgMembers().then(({ OrgMembers }) => {
                OrgMembers_Filter.HasLoaded = true;
                OrgMembers_Filter.IsLoading = false;
                OrgMembers_Filter.OrgMembers = OrgMembers;

                Filters.OrgMembers = OrgMembers_Filter;

                this.setState({ Filters });
            })
        });
    }

    onMakeEmployeeUpdate = () => {
        var { OptionType: { value: RequestType } } = this.state.EmployeeUpdateOptions;
        var { CompanyId, OrderId, TrainerId: DHCId, UserId: EmployeeId } = this.state.ShowEmployeeOptionsModal_Data;

        if (RequestType === 'Switch') var NewDHCId = this.state.EmployeeUpdateOptions.SelectedDHC.UserId;

        this.props.MakeEmployeeUpdate({ CompanyId, DHCId, EmployeeId, NewDHCId, OrderId, RequestType }).then(() => {
            var DHCIds = this.state.Filters.OrgMembers.Selected.map(({ UserId }) => UserId).join(',');

            this.props.GetCompany({ CompanyId, DHCIds }).then(({ Company }) => {
                this.setState({ InsertEditCompany: { ...Company } });
                this.onToggleShowEmployeeOptionsModal({ ShowEmployeeOptionsModal: false });
            });
        })
    }

    onMarkOrderAsEnded = () => {
        var { OrderId } = this.state.InsertEditOrder;

        this.props.MarkOrderAsEnded({ OrderId }).then(() => {
            this.onToggleShowInsertEditOrder({ ShowInsertEditOrder: false });
        })
    }

    onRemoveCompanyEmployee = ({ CompanyId, UserId }) => {
        this.props.DeleteCompanyEmployees({ CompanyId, UserIds: `${UserId}`}).then(({ Company }) => {
            this.setState({ InsertEditCompany: { ...this.state.InsertEditCompany, ...Company } });
        });
    }

    onSetActiveTab = ActiveTab => {
        this.setState({ InsertEditCompany: { ...this.state.InsertEditCompany, ActiveTab } });
    }

    onToggleEmployeeType = ShowInsertEditCompanyModal_EmployeeType => {
        this.setState({ ShowInsertEditCompanyModal_EmployeeType });
    }

    onToggleHasWhitelabeling = event => {
        var InsertEditCompany = { ...this.state.InsertEditCompany };
        InsertEditCompany.HasWhitelabeling = event.target.checked;

        this.setState({ InsertEditCompany });
    }

    onToggleShowAddEmployeesModal = ({ ShowAddEmployeesModal, ShowAddEmployeesModal_CompanyId = null, ShowAddEmployeesModal_CompanyName = '' }) => {
        this.setState({ ShowAddEmployeesModal, ShowAddEmployeesModal_CompanyId, ShowAddEmployeesModal_CompanyName, ShowAddEmployeesModal_Emails: [], ShowAddEmployeesModal_TrainerEmail: '' });
    }

    onToggleShowEmployeeOptionsModal = ({ ShowEmployeeOptionsModal_Data = {}, ShowEmployeeOptionsModal }) => {
        this.setState({ EmployeeUpdateOptions: { ...DEFAULT_EMPLOYEE_OPTIONS_POPOVER }, ShowEmployeeOptionsModal_Data, ShowEmployeeOptionsModal });
    }

    onToggleShowInsertEditCompanyModal = ({ InsertEditCompany = {}, ShowInsertEditCompanyModal }) => {
        var LoadCompanyDetails = false;
        // Editing a company, so get company details
            if (!!+InsertEditCompany.CompanyId) LoadCompanyDetails = true;

        InsertEditCompany = { ...DEFAULT_INSERT_EDIT_COMPANY, ...InsertEditCompany };

        this.onToggleShowInsertEditOrder({ ShowInsertEditOrder: false });

        this.setState({ InsertEditCompany, ShowInsertEditCompanyModal, ShowInsertEditCompanyModal_EmployeeType: 'Active' }, () => {
            if (LoadCompanyDetails) {
                var DHCIds = this.state.Filters.OrgMembers.Selected.map(({ UserId }) => UserId).join(',');

                this.props.GetCompany({ CompanyId: InsertEditCompany.CompanyId, DHCIds }).then(({ Company }) => {
                    this.setState({ InsertEditCompany: { ...InsertEditCompany, ...Company }, Sorting: { ...DEFAULT_SORTING } });
                })
            }
        });
    }

    onToggleShowInsertEditOrder = ({ InsertEditOrder = {}, ShowInsertEditOrder }) => {
        InsertEditOrder = { ...DEFAULT_INSERT_EDIT_ORDER, ...InsertEditOrder };

        var Filters = { ...this.state.Filters };
        if (Object.keys(InsertEditOrder).length === 0) Filters.Products.Selected = [];
        else Filters.Products.Selected = InsertEditOrder.Products;

        this.setState({ Filters, InsertEditOrder, ShowInsertEditOrder });
    }

    onUpdateEmployeeSorting = ({ data, ArrayName, type }) => {
        var { Column, Direction } = this.state.Sorting[ArrayName];
        
        if (type === 'Column') Column = data.value;
        else if (type === 'Direction') Direction = data.value;

        var arr = [ ...this.state.InsertEditCompany[ArrayName].sort(SortValues(Column, Direction)) ];

        var InsertEditCompany = { ...this.state.InsertEditCompany };
        InsertEditCompany[ArrayName] = [ ...arr ];

        var Sorting = { ...this.state.Sorting };
        if (type === 'Column') Sorting[ArrayName] = { ...Sorting[ArrayName], Column: data.value };
        else if (type === 'Direction') Sorting[ArrayName] = { ...Sorting[ArrayName], Direction: data.value };

        this.setState({ InsertEditCompany, Sorting });
    }

    onUploadEmployees = event => {
        if (event.target.files.length) {
            var EmployeesFile = event.target.files[0];

            this.props.UpdateCompanyEmployees({ EmployeesFile }).then(() => {
                this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: false });
                // this.onGetCompanies();

                toast.success('Processing started. Email will be sent upon completion', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

            event.target.value = null;
        }
    }

    renderAddEmployeesModal = () => {
        var { t } = this.props;
        var { TryingAddCompanyEmployees } = this.props;
        var { ShowAddEmployeesModal, ShowAddEmployeesModal_TrainerEmail } = this.state;

        if (ShowAddEmployeesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onAddCompanyEmployees}
                    BottomButtonDisabled={!ShowAddEmployeesModal_TrainerEmail}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderAddEmployeesModal_Information}
                    Loading={(TryingAddCompanyEmployees)}
                    OnHide={() => this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: false })}
                    Show={ShowAddEmployeesModal}
                />
            );
        }
    }

    renderAddEmployeesModal_Information = () => {
        var { t } = this.props;
        var { Companies, ShowAddEmployeesModal_CompanyId, ShowAddEmployeesModal_CompanyName, ShowAddEmployeesModal_Emails, ShowAddEmployeesModal_TrainerEmail } = this.state;

        return (
            <Styles.InsertEditCompanyModalContainer className="InsertEditCompanyModalContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text="Add Employees" TextAlign="center" />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Choose company" TextAlign="center" />

                <Select
                    getOptionLabel={option => option.CompanyName}
                    getOptionValue={option => option.CompanyId}
                    onChange={this.onChangeAddEmployees_Company}
                    options={Companies}
                    placeholder="Companies"
                    styles={DropdownStyles}
                    value={ShowAddEmployeesModal_CompanyId && { CompanyId: ShowAddEmployeesModal_CompanyId, CompanyName: ShowAddEmployeesModal_CompanyName }}
                />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Enter DHC email to preregister any new users" TextAlign="center" />

                <Spacer Size="extra-small" />

                <Styles.SearchCompaniesInput
                    AutoFocus
                    FontSize="medium-1"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeTrainerEmail}
                    Placeholder="DHC email"
                    Size="medium"
                    Type="text"
                    Value={ShowAddEmployeesModal_TrainerEmail}
                />

                <Spacer Size="medium" />

                {
                    ShowAddEmployeesModal_Emails.map(({ Email, Valid }, EmailIndex) => {
                        return (
                            <Styles.InputContainer className="InputContainer" key={EmailIndex}>
                                <Styles.InputInnerContainer className="InputInnerContainer">
                                    <Styles.StyledInput FontSize="medium-2" NoLabel NoMargin OnBlur={() => this.onBlurEmail(EmailIndex)} OnChange={event => this.onChangeEmail({ event, EmailIndex })} Placeholder={t('email_with_first_char_uppercase')} Size="medium" Type="text" Value={Email} />

                                    {
                                        EmailIndex !== 0 ?
                                        <Styles.StyledDeleteIcon ImageSrc={RedX} OnClick={() => this.onDeleteEmail(EmailIndex)} />
                                    :
                                        <div />
                                    }
                                </Styles.InputInnerContainer>

                                {!!Email.length && !Valid && <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('provide_valid_email_address')} TextAlign="left" />}
                            </Styles.InputContainer>           
                        );
                    })
                }

                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onAddEmail, Title: "Add New Employee Email" }]}
                    ButtonWidth="fit-content"
                    NotTouching
                />
            </Styles.InsertEditCompanyModalContainer>
        );
    }

    renderCompanies = () => {
        var { Companies, SearchCompanyName } = this.state;

        if (SearchCompanyName) {
            SearchCompanyName = SearchCompanyName.toLowerCase();
            Companies = Companies.filter(({ CompanyName }) => CompanyName.toLowerCase().includes(SearchCompanyName));
        }

        return (
            <Styles.CompaniesContainer className="CompaniesContainer">
                <Styles.CompaniesHeaderRow className="CompaniesHeaderRow">
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="CompanyId" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Name" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Pending Employees" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Not Started" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Active Employees" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Satisfaction" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Tasks/sessions" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Video calls" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Switch request" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Wants to quit" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                </Styles.CompaniesHeaderRow>

                {
                    Companies.map(Company => {
                        var { Activities, CompanyId, CompanyName, NumEmployees, NotStarted, Pending, Satisfaction, VideoCalls, WantsToQuit, WantsToSwitch } = Company;

                        return (
                            <Styles.CompaniesBodyRow key={CompanyId} className="CompaniesBodyRow" onClick={() => this.onToggleShowInsertEditCompanyModal({ InsertEditCompany: Company, ShowInsertEditCompanyModal: true })}>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${CompanyId}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={CompanyName} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Pending}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${NotStarted}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${NumEmployees}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Satisfaction}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Activities}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${VideoCalls}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${WantsToSwitch}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${WantsToQuit}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                            </Styles.CompaniesBodyRow>
                        );
                    })
                }
            </Styles.CompaniesContainer>
        );
    }

    renderDHCFilter = () => {
        var { t } = this.props;
        var { IsLoading, OrgMembers } = this.state.Filters.OrgMembers;

        return (
            <>
                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="Filter by DHC" TextAlign="left" />
                    
                <Spacer Size="extra-small" />
                
                <Select
                    closeMenuOnSelect={false}
                    defaultValue={[]}
                    getOptionLabel={option => option.DisplayName}
                    getOptionValue={option => option.UserId}
                    components={{ ValueContainer }}
                    hideSelectedOptions={false}
                    isLoading={IsLoading}
                    isMulti
                    isSearchable
                    onChange={this.onChangeFilter_DHCs}
                    onFocus={this.onFocusFilter_DHCs}
                    options={OrgMembers}
                    placeholder={t('_select')}
                    styles={customStyles}
                />
            </>
        );
    }

    renderEmployeeOptionalsModal = () => {
        var { t } = this.props;
        var { TryingCompaniesAction } = this.props;
        var { ShowEmployeeOptionsModal } = this.state;

        if (ShowEmployeeOptionsModal) {
            return (
                <Info
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderEmployeeOptionalsModal_Information}
                    Loading={(TryingCompaniesAction)}
                    OnHide={() => this.onToggleShowEmployeeOptionsModal({ ShowEmployeeOptionsModal: false })}
                    Show={ShowEmployeeOptionsModal}
                />
            );
        }
    }

    renderEmployeeOptionalsModal_Information = () => {
        var { t } = this.props;
        var { Email, FullName, TrainerEmail, TrainerFullName } = this.state.ShowEmployeeOptionsModal_Data;
        var { IsLoading, OrgMembers } = this.state.Filters.OrgMembers;
        var { OptionType, SelectedDHC } = this.state.EmployeeUpdateOptions;
        
        var OptionTypeValue = OptionType ? OptionType.value : '';

        var Disabled = false;
        if (OptionTypeValue === 'Switch' && !SelectedDHC) Disabled = true;

        return (
            <>
                <Styles.InsertEditCompanyModalContainer className="InsertEditCompanyModalContainer">
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text="Employee options" TextAlign="center" />
                    
                    <Spacer Size="small" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text="Employee:" TextAlign="center" />
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={FullName} TextAlign="center" />
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={Email} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text="DHC:" TextAlign="center" />
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={TrainerFullName} TextAlign="center" />
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={TrainerEmail} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" Text="Select option" TextAlign="center" />
                    <Select
                        value={OptionType}
                        closeMenuOnSelect={true}
                        isSearchable
                        onChange={this.onChangeEmployeeOptionsModal_OptionType}
                        menuPortalTarget={document.body}
                        options={EMPLOYEE_OPTIONS_ARRAY}
                        placeholder={t('_select')}
                        styles={{
                            ...customStyles,
                            menuPortal: (base) => ({
                                ...base,
                                zIndex: 9999, // Ensure dropdown is above the modal
                            }),
                        }}
                    />

                    <Spacer Size="small" />

                    {/* Show DHC Selection If Switched */}
                        {
                            OptionTypeValue === 'Switch' &&
                            <>
                                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" Text="Choose new DHC" TextAlign="center" />
                                <Select
                                    menuPortalTarget={document.body}
                                    value={SelectedDHC}
                                    closeMenuOnSelect={true}
                                    defaultValue={[]}
                                    getOptionLabel={option => option.DisplayName}
                                    getOptionValue={option => option.UserId}
                                    components={{ ValueContainer }}
                                    hideSelectedOptions={false}
                                    isLoading={IsLoading}
                                    isSearchable
                                    onChange={this.onChangeFilter_DHCs_EmployeeOptions}
                                    onFocus={this.onFocusFilter_DHCs}
                                    options={OrgMembers}
                                    placeholder={t('_select')}
                                    styles={{
                                        ...customStyles,
                                        menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999, // Ensure dropdown is above the modal
                                        }),
                                    }}
                                />

                                <Spacer Size="small" />
                            </>
                        }

                    {/* Switched && New Coach Selected OR CompanyEnded, Quit OR NotReached */}
                        {
                            !!OptionTypeValue &&
                            <>      
                                <ButtonGroup
                                    Buttons={[
                                        { BackgroundColor: 'white-concrete-hover', BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowEmployeeOptionsModal({ ShowEmployeeOptionsModal: false }), Title: t('_cancel') },
                                        { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: this.onMakeEmployeeUpdate, Title: t('_save') }
                                    ]}
                                    ButtonWidth="fit-content"
                                    NotTouching
                                    OwnRows={false}
                                />
                            </>
                        }
                </Styles.InsertEditCompanyModalContainer>
            </>
        );
    }

    renderEmployeeSorting = ({ ArrayName }) => {
        var SelectOptions = [];
        var DefaultArr = [];

        if (ArrayName === 'PendingEmployees') DefaultArr = [ ...PENDING_EMPLOYEES_COLUMNS ];
        else if (ArrayName === 'NotStartedEmployees') DefaultArr = [ ...NOTSTARTED_EMPLOYEES_COLUMNS ];
        else if (ArrayName === 'NotReachedEmployees') DefaultArr = [ ...NOTREACHED_EMPLOYEES_COLUMNS ];
        else if (ArrayName === 'ActiveEmployees') DefaultArr = [ ...ACTIVE_EMPLOYEES_COLUMNS ];
        else if (ArrayName === 'SwitchedEmployees') DefaultArr = [ ...SWITCHED_EMPLOYEES_COLUMNS ];
        else if (ArrayName === 'QuitPhase1Employees') DefaultArr = [ ...QUITPHASE1_EMPLOYEES_COLUMNS ];
        else if (ArrayName === 'CompanyEndedEmployees') DefaultArr = [ ...COMPANYENDED_EMPLOYEES_COLUMNS ];
        else if (ArrayName === 'QuitPhase1PlusEmployees') DefaultArr = [ ...QUITPHASE1PLUS_EMPLOYEES_COLUMNS ];

        SelectOptions = DefaultArr.map(({ label, value }) => ({ label, value }));

        var { Column, Direction } = this.state.Sorting[ArrayName];

        var SelectedColumn = { label: Column, value: Column };
        var SelectedDirection = { label: Direction, value: Direction };

        return (
            <>
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Sort by..." TextAlign="left" />

                <Styles.EmployeeSortingContainer>
                    <Select
                        value={SelectedColumn}
                        onChange={data => this.onUpdateEmployeeSorting({ data, ArrayName, type: 'Column' })}
                        options={SelectOptions}
                        placeholder="Sorting options"
                        styles={DropdownStyles}
                    />
                    <Select
                        value={SelectedDirection}
                        onChange={data => this.onUpdateEmployeeSorting({ data, ArrayName, type: 'Direction' })}
                        options={[{ label: 'ASC', value: 'ASC' }, { label: 'DESC', value: 'DESC' }]}
                        placeholder="Sorting direction"
                        styles={DropdownStyles}
                    />
                </Styles.EmployeeSortingContainer>
            </>
        );
    }

    renderEmployees = () => {
        var { t } = this.props;

        if (this.props.TryingGetCompany) {
            return (
                <>
                    <Spacer Size="medium" />
                    
                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('loading')} TextAlign="center" />
                </>
            );
        }

        var { InsertEditCompany: { ActiveEmployees, CompanyId, CompanyEndedEmployees, NotReachedEmployees, NotStartedEmployees, PendingEmployees, QuitPhase1Employees, QuitPhase1PlusEmployees, SwitchedEmployees }, ShowInsertEditCompanyModal_EmployeeType } = this.state;

        return (
            <>
                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('employees')} TextAlign="center" />

                {
                    ([ 1, 2, 6, 18097 ].indexOf(+this.props.UserDetails.UserId) > -1) &&
                    <>
                        <Spacer Size="small" />

                        <ButtonGroup
                            Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: EditIcon, IconPosition: 'left', IsInput: true, InputProps: { accept: "csv,.csv", id: "PendingEmployees", type: "file", onChange: this.onUploadEmployees }, Title: t('Upload') }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                        />
                    </>
                }

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'Pending' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Pending' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'Pending' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Pending' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('Pending'), Title: t('pending') },
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'NotStarted' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'NotStarted' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'NotStarted' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'NotStarted' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('NotStarted'), Title: 'Not started' },
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'NotReached' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'NotReached' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'NotReached' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'NotReached' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('NotReached'), Title: 'Not reached' },
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'Active' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Active' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'Active' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Active' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('Active'), Title: t('active') },
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'Switched' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Switched' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'Switched' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'Switched' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('Switched'), Title: 'Switched' },
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('QuitPhase1'), Title: 'Opted out' },
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'CompanyEnded' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'CompanyEnded' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'CompanyEnded' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'CompanyEnded' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('CompanyEnded'), Title: 'Company end' },
                        { BackgroundColor: ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1Plus' ? 'blue-abel' : 'white-concrete', BackgroundColorHover: ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1Plus' ? 'blue-abel-hover' : 'white-concrete-hover', Color: ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1Plus' ? 'white' : 'black', ColorHover: ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1Plus' ? 'white' : 'black', OnClick: () => this.onToggleEmployeeType('QuitPhase1Plus'), Title: 'Quit after P1' }
                    ]}
                    ButtonWidth="fit-content"
                />

                <Styles.EmployeesSectionContainer>
                    {
                        (
                            (ShowInsertEditCompanyModal_EmployeeType === 'Pending' && PendingEmployees.length === 0) ||
                            (ShowInsertEditCompanyModal_EmployeeType === 'NotStarted' && NotStartedEmployees.length === 0) ||
                            (ShowInsertEditCompanyModal_EmployeeType === 'NotReached' && NotReachedEmployees.length === 0) ||
                            (ShowInsertEditCompanyModal_EmployeeType === 'Active' && ActiveEmployees.length === 0) ||
                            (ShowInsertEditCompanyModal_EmployeeType === 'Switched' && SwitchedEmployees.length === 0) ||
                            (ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1' && QuitPhase1Employees.length === 0) ||
                            (ShowInsertEditCompanyModal_EmployeeType === 'CompanyEnded' && CompanyEndedEmployees.length === 0) ||
                            (ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1Plus' && QuitPhase1PlusEmployees.length === 0)
                        ) &&
                        <>
                            <Spacer Size="small" />

                            <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('no_employees')} TextAlign="center" />
                        </>
                    }

                {/* Pending Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'Pending' && PendingEmployees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${PendingEmployees.length} employees`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[{ BackgroundColor: "blue-abel", BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onDownloadEmployees({ CompanyId, EmployeeType: 'Pending' }), Title: t('download') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth="fit-content"
                            />

                            <Spacer Size="extra-small" />

                            {this.renderEmployeeSorting({ ArrayName: 'PendingEmployees' })}

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowPending className="EmployeesHeaderRowPending">
                                    {
                                        PENDING_EMPLOYEES_COLUMNS.map(({ id, label, value }) => {
                                            return (
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" key={id}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={label} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            );
                                        })
                                    }
                                </Styles.EmployeesHeaderRowPending>

                                {
                                    PendingEmployees.map(Company => {
                                        var { AlgoDHC, ChosenDHC, FullName, Email, PhoneNumber, RegisteredDate, UserId } = Company;

                                        return (
                                            <Styles.EmployeesBodyRowPending key={UserId} className="EmployeesBodyRowPending">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${PhoneNumber}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${ChosenDHC}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${AlgoDHC}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(RegisteredDate).toLocaleString()} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowPending>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }

                {/* Not Started Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'NotStarted' && NotStartedEmployees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${NotStartedEmployees.length} employees`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[{ BackgroundColor: "blue-abel", BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onDownloadEmployees({ CompanyId, EmployeeType: 'Pending' }), Title: t('download') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth="fit-content"
                            />

                            <Spacer Size="extra-small" />

                            {this.renderEmployeeSorting({ ArrayName: 'NotStartedEmployees' })}

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowNotStarted className="EmployeesHeaderRowNotStarted">
                                    {
                                        NOTSTARTED_EMPLOYEES_COLUMNS.map(({ id, label, value }) => {
                                            return (
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" key={id}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={label} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            );
                                        })
                                    }
                                    <div />
                                </Styles.EmployeesHeaderRowNotStarted>

                                {
                                    NotStartedEmployees.map(Employee => {
                                        var { DaysAgo, Email, FullName, PhoneNumber, NotStartedDate, TrainerEmail, TrainerFullName, UserId } = Employee;

                                        var ShowWarning = DaysAgo >= 7;

                                        return (
                                            <Styles.EmployeesBodyRowNotStarted key={UserId} className="EmployeesBodyRowNotStarted" ShowWarning={ShowWarning}>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${PhoneNumber}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerFullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerEmail}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(NotStartedDate).toLocaleString()} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${DaysAgo} days ago`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" onClick={() => this.onToggleShowEmployeeOptionsModal({ ShowEmployeeOptionsModal_Data: Employee, ShowEmployeeOptionsModal: true })}>
                                                    <Styles.StyledMoreIcon ImageSrc={MoreDots} />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowNotStarted>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }

                {/* NotReached Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'NotReached' && NotReachedEmployees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${NotReachedEmployees.length} employees`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            {this.renderEmployeeSorting({ ArrayName: 'NotReachedEmployees' })}

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowNotReached className="EmployeesHeaderRowNotReached">
                                    {
                                        NOTREACHED_EMPLOYEES_COLUMNS.map(({ id, label, value }) => {
                                            return (
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" key={id}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={label} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            );
                                        })
                                    }
                                </Styles.EmployeesHeaderRowNotReached>

                                {
                                    NotReachedEmployees.map(Company => {
                                        var { DaysAgo, Email, FullName, NotReachedDate, PhoneNumber, TrainerEmail, TrainerFullName, UserId } = Company;

                                        return (
                                            <Styles.EmployeesBodyRowNotReached key={UserId} className="EmployeesBodyRowNotReached">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${PhoneNumber}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerFullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerEmail}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(NotReachedDate).toLocaleDateString()} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${DaysAgo} days ago`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowNotReached>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }

                {/* Active Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'Active' && ActiveEmployees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${ActiveEmployees.length} employees`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[{ BackgroundColor: "blue-abel", BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onDownloadEmployees({ CompanyId, EmployeeType: 'Pending' }), Title: t('download') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth="fit-content"
                            />

                            <Spacer Size="extra-small" />

                            {this.renderEmployeeSorting({ ArrayName: 'ActiveEmployees' })}

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowActive className="EmployeesHeaderRowActive">
                                    {
                                        ACTIVE_EMPLOYEES_COLUMNS.map(({ id, label, value }) => {
                                            return (
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" key={id}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={label} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            );
                                        })
                                    }
                                    <div />
                                </Styles.EmployeesHeaderRowActive>

                                {
                                    ActiveEmployees.map(Employee => {
                                        var { Activities, Email, EndDate, FullName, LastVideoCall, LastVideoCall_DaysAgo, PhoneNumber, ProductName, Satisfaction, StartDate, TrainerEmail, TrainerFullName, UserId, VideoCallDuration, WantsToQuit, WantsToSwitch } = Employee;

                                        return (
                                            <Styles.EmployeesBodyRowActive key={UserId} className="EmployeesBodyRowActive">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${PhoneNumber}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerFullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerEmail}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(StartDate).toLocaleDateString()} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(EndDate).toLocaleDateString()} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${ProductName}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Satisfaction}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Activities}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${VideoCallDuration} mins`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${LastVideoCall_DaysAgo} days ago`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={WantsToSwitch ? t('_yes') : t('_no')} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={WantsToQuit ? t('_yes') : t('_no')} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" onClick={() => this.onToggleShowEmployeeOptionsModal({ ShowEmployeeOptionsModal_Data: Employee, ShowEmployeeOptionsModal: true })}>
                                                    <Styles.StyledMoreIcon ImageSrc={MoreDots} />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowActive>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }

                {/* Switched Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'Switched' && SwitchedEmployees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${SwitchedEmployees.length} employees`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            {this.renderEmployeeSorting({ ArrayName: 'SwitchedEmployees' })}

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowSwitched className="EmployeesHeaderRowSwitched">
                                    {
                                        SWITCHED_EMPLOYEES_COLUMNS.map(({ id, label, value }) => {
                                            return (
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" key={id}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={label} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            );
                                        })
                                    }
                                </Styles.EmployeesHeaderRowSwitched>

                                {
                                    SwitchedEmployees.map(Company => {
                                        var { DaysAgo, Email, FullName, PhoneNumber, From_TrainerEmail, From_TrainerFullName, To_TrainerEmail, To_TrainerFullName, SwitchedDate, UserId } = Company;

                                        return (
                                            <Styles.EmployeesBodyRowSwitched key={UserId} className="EmployeesBodyRowSwitched">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${PhoneNumber}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${From_TrainerFullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${From_TrainerEmail}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${To_TrainerFullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${To_TrainerEmail}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(SwitchedDate).toLocaleDateString()} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${DaysAgo} days ago`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowSwitched>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }

                {/* QuitPhase1 Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1' && QuitPhase1Employees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${QuitPhase1Employees.length} employees`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            {this.renderEmployeeSorting({ ArrayName: 'QuitPhase1Employees' })}

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowQuitPhase1 className="EmployeesHeaderRowQuitPhase1">
                                    {
                                        QUITPHASE1_EMPLOYEES_COLUMNS.map(({ id, label, value }) => {
                                            return (
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" key={id}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={label} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            );
                                        })
                                    }
                                </Styles.EmployeesHeaderRowQuitPhase1>

                                {
                                    QuitPhase1Employees.map(Company => {
                                        var { DaysAgo, Email, FullName, PhoneNumber, TrainerEmail, TrainerFullName, QuitDate, UserId } = Company;

                                        return (
                                            <Styles.EmployeesBodyRowQuitPhase1 key={UserId} className="EmployeesBodyRowQuitPhase1">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${PhoneNumber}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerFullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerEmail}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(QuitDate).toLocaleDateString()} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${DaysAgo} days ago`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowQuitPhase1>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }

                {/* CompanyEnded Employees */}
                    {
                        (ShowInsertEditCompanyModal_EmployeeType === 'CompanyEnded' && CompanyEndedEmployees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${CompanyEndedEmployees.length} employees`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            {this.renderEmployeeSorting({ ArrayName: 'CompanyEndedEmployees' })}

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowCompanyEnded className="EmployeesHeaderRowCompanyEnded">
                                    {
                                        COMPANYENDED_EMPLOYEES_COLUMNS.map(({ id, label, value }) => {
                                            return (
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" key={id}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={label} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            );
                                        })
                                    }
                                </Styles.EmployeesHeaderRowCompanyEnded>

                                {
                                    CompanyEndedEmployees.map(Company => {
                                        var { DaysAgo, Email, FullName, PhoneNumber, EndedDate, UserId } = Company;

                                        return (
                                            <Styles.EmployeesBodyRowCompanyEnded key={UserId} className="EmployeesBodyRowCompanyEnded">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${PhoneNumber}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(EndedDate).toLocaleDateString()} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${DaysAgo} days ago`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowCompanyEnded>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }

                {/* QuitPhase1Plus Employees */}
                {
                        (ShowInsertEditCompanyModal_EmployeeType === 'QuitPhase1Plus' && QuitPhase1PlusEmployees.length > 0) &&
                        <>
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${QuitPhase1PlusEmployees.length} employees`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            {this.renderEmployeeSorting({ ArrayName: 'QuitPhase1PlusEmployees' })}

                            <Spacer Size="extra-small" />

                            <Styles.EmployeesContainer className="EmployeesContainer">
                                <Styles.EmployeesHeaderRowQuitPhase1Plus className="EmployeesHeaderRowQuitPhase1Plus">
                                    {
                                        QUITPHASE1PLUS_EMPLOYEES_COLUMNS.map(({ id, label, value }) => {
                                            return (
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer" key={id}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={label} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            );
                                        })
                                    }
                                </Styles.EmployeesHeaderRowQuitPhase1Plus>

                                {
                                    QuitPhase1PlusEmployees.map(Company => {
                                        var { DaysAgo, Email, FullName, PhoneNumber, TrainerEmail, TrainerFullName, QuitDate, UserId } = Company;

                                        return (
                                            <Styles.EmployeesBodyRowQuitPhase1Plus key={UserId} className="EmployeesBodyRowQuitPhase1Plus">
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${PhoneNumber}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerFullName}`} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerEmail}`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                                <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(QuitDate).toLocaleDateString()} TextAlign="center" />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${DaysAgo} days ago`} TextAlign="center" />
                                                </Styles.EmployeesColumnContainer>
                                            </Styles.EmployeesBodyRowQuitPhase1Plus>
                                        );
                                    })
                                }
                            </Styles.EmployeesContainer>
                        </>
                    }
                </Styles.EmployeesSectionContainer>
            </>
        );
    }

    renderInsertEditCompanyModel = () => {
        var { t } = this.props;
        var { TryingCompaniesAction, TryingGetCompany, TryingDeleteCompanyEmployees, TryingInsertEditCompany } = this.props;
        var { ShowAddEmployeesModal, ShowEmployeeOptionsModal, ShowInsertEditCompanyModal } = this.state;

        if (ShowInsertEditCompanyModal && !ShowAddEmployeesModal && !ShowEmployeeOptionsModal) {
            return (
                <Info
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderInsertEditCompanyModel_Information}
                    Loading={(TryingCompaniesAction || TryingGetCompany || TryingDeleteCompanyEmployees || TryingInsertEditCompany)}
                    OnHide={() => this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: false })}
                    Show={ShowInsertEditCompanyModal}
                />
            );
        }
    }

    renderInsertEditCompanyModel_Information = () => {
        var { t } = this.props;
        var { ActiveTab, CompanyId, CompanyName } = this.state.InsertEditCompany;

        return (
            <>
                {(this.props.TryingDownloadEmployees) && <Loading />}

                <Styles.InsertEditCompanyModalContainer className="InsertEditCompanyModalContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('company_detail')} TextAlign="center" />

                    <Spacer Size="medium" />

                    <Styles.SearchAndAddContainer className="SearchAndAddContainer">
                        <Styles.SearchCompaniesInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurCompanyName}
                            OnChange={this.onChangeCompanyName}
                            Placeholder="Company name"
                            Size="medium"
                            Type="text"
                            Value={CompanyName}
                        />
                    </Styles.SearchAndAddContainer>

                    {
                        !!+CompanyId &&
                        <>
                            <Spacer Size="small" />

                            <ButtonGroup
                                Buttons={[
                                    { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', BackgroundColorSelected: 'blue-astronaut', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'medium', OnClick: () => this.onSetActiveTab('Employees'), Selected: ActiveTab === 'Employees', Size: "small", TextAlign: 'left', Title: t('employees') },
                                    { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', BackgroundColorSelected: 'blue-astronaut', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'medium', OnClick: () => this.onSetActiveTab('Orders'), Selected: ActiveTab === 'Orders', Size: "small", TextAlign: 'left', Title: t('orders') }
                                ]}
                                ButtonWidth='fit-content'
                                NotTouching
                            />

                            {
                                ActiveTab === 'Employees' ?
                                this.renderEmployees()
                            :
                                this.renderOrders()
                            }
                        </>
                    }
                </Styles.InsertEditCompanyModalContainer>
            </>
        );
    }

    renderInsertEditOrder = () => {
        var { t } = this.props;
        var { AgreementDate, DeliveryDuration, DeliveryStartDate, OrderId, RecruitingEndDate, RecruitingStartDate, StatusMeetingDate } = this.state.InsertEditOrder;

        var Disabled = false;

        if (!AgreementDate || !DeliveryDuration || !DeliveryStartDate || !RecruitingStartDate || !RecruitingEndDate || !StatusMeetingDate) Disabled = true;

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white-concrete-hover', BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowInsertEditOrder({ ShowInsertEditOrder: false }), Title: t('_cancel') });
        Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: () => this.onInsertEditOrder(), Title: t('_save') });
        if (!!+OrderId) Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onMarkOrderAsEnded(), Title: 'Company ended' });

        return (
            <>
                <Styles.InsertEditOrderContainer className="InsertEditOrderContainer">
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={!!+OrderId ? `${t('edit_order')} ${OrderId}` : t('new_order')} TextAlign="center" />

                    <Spacer Size="small" />

                    <Styles.InsertEditOrderInnerContainer className="InsertEditOrderInnerContainer">
                        <Styles.FormQuestionContainer className="FormQuestionContainer">
                            <MultiText TextAlign="left"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: 'Agreement date' },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' }
                                ]}
                            />

                            <Styles.StyledAccountInput
                                FontSize="medium-1"
                                OnChange={event => this.onChangeOrderInput({ event, type: 'AgreementDate' })}
                                Size="large"
                                Type="date"
                                Value={AgreementDate}
                            />
                        </Styles.FormQuestionContainer>

                        <Styles.FormQuestionContainer className="FormQuestionContainer">
                            <MultiText TextAlign="left"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: 'Recruiting start date' },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' }
                                ]}
                            />

                            <Styles.StyledAccountInput
                                FontSize="medium-1"
                                OnChange={event => this.onChangeOrderInput({ event, type: 'RecruitingStartDate' })}
                                Size="large"
                                Type="date"
                                Value={RecruitingStartDate}
                            />
                        </Styles.FormQuestionContainer>

                        <Styles.FormQuestionContainer className="FormQuestionContainer">
                            <MultiText TextAlign="left"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: 'Delivery start date' },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' }
                                ]}
                            />

                            <Styles.StyledAccountInput
                                FontSize="medium-1"
                                OnChange={event => this.onChangeOrderInput({ event, type: 'DeliveryStartDate' })}
                                Size="large"
                                Type="date"
                                Value={DeliveryStartDate}
                            />
                        </Styles.FormQuestionContainer>

                        <Styles.FormQuestionContainer className="FormQuestionContainer">
                            <MultiText TextAlign="left"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: 'Recruiting end date' },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' }
                                ]}
                            />

                            <Styles.StyledAccountInput
                                FontSize="medium-1"
                                OnChange={event => this.onChangeOrderInput({ event, type: 'RecruitingEndDate' })}
                                Size="large"
                                Type="date"
                                Value={RecruitingEndDate}
                            />
                        </Styles.FormQuestionContainer>

                        <Styles.FormQuestionContainer className="FormQuestionContainer">
                            <MultiText TextAlign="left"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: 'Delivery duration' },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' }
                                ]}
                            />

                            <Styles.StyledAccountInput
                                FontSize="medium-1"
                                OnChange={event => this.onChangeOrderInput({ event, type: 'DeliveryDuration' })}
                                Placeholder={t('duration')}
                                Size="large"
                                Type="number"
                                Value={DeliveryDuration}
                            />
                        </Styles.FormQuestionContainer>

                        <Styles.FormQuestionContainer className="FormQuestionContainer">
                            <MultiText TextAlign="left"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: 'Status meeting date' },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' }
                                ]}
                            />

                            <Styles.StyledAccountInput
                                FontSize="medium-1"
                                OnChange={event => this.onChangeOrderInput({ event, type: 'StatusMeetingDate' })}
                                Size="large"
                                Type="date"
                                Value={StatusMeetingDate}
                            />
                        </Styles.FormQuestionContainer>
                    </Styles.InsertEditOrderInnerContainer>

                    {/* Products */}
                        <Spacer Size="small" />

                        {this.renderProducts()}

                    {/* Save Button */}
                        <Spacer Size="small" />
    
                        <ButtonGroup
                            Buttons={Buttons}
                            ButtonWidth="fit-content"
                            NotTouching
                            OwnRows={false}
                        />
                </Styles.InsertEditOrderContainer>
            </>
        );
    }

    renderInviteLink = InviteLink => {
        var { t } = this.props;

        var InviteLinkUrl = `${COMPANY_INVITE_URL}?code=${InviteLink}`;

        return (
            <Styles.InviteLinkContainer className="InviteLinkContainer">
                <MultiText TextAlign="left"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('company_invite_link_title')}:` },
                        { FontColor: 'blue-abel', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: InviteLinkUrl }
                    ]}
                />

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'medium', Icon: Copy, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: () => this.onCopyInviteLink(InviteLinkUrl), Size: "small", TextAlign: 'left', Title: t('copy') },
                        { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'medium', Icon: EditIcon, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: this.onResetInviteLink, Size: "small", TextAlign: 'left', Title: t('reset') }
                        // { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', Icon: SwapIcon, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: OnToggleShowChangeExerciseModal, Size: 'small', TextAlign: 'left', Title: t('change_exercise') }
                    ]}
                    ButtonWidth='fit-content'
                    NotTouching
                />
            </Styles.InviteLinkContainer>
        );
    }

    renderOrders = () => {
        var { t } = this.props;
        var { InsertEditCompany: { Orders }, ShowInsertEditOrder } = this.state;

        return (
            <Styles.OrdersAreaContainer className="OrdersAreaContainer">
                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('orders')} TextAlign="center" />

                {
                    !ShowInsertEditOrder &&
                    <>
                        <Spacer Size="small" />
        
                        <ButtonGroup
                            Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowInsertEditOrder({ ShowInsertEditOrder: true }), Title: t('new_order') }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                        />
                    </>
                }

                {/* New order */}
                    {
                        !!ShowInsertEditOrder &&
                        <>
                            <Spacer Size="small" />

                            {this.renderInsertEditOrder()}
                        </>
                    }

                <Spacer Size="medium" />

                {
                    !Orders.length ?
                    <>
                        <Spacer Size="small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="No orders" TextAlign="center" />
                    </>
                :
                    <>
                        <Styles.OrdersContainer className="OrdersContainer">
                            <Styles.OrdersHeaderRow className="OrdersHeaderRow">
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="OrderId" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Order Nr" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Agreement Date" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Recruiting Start Date" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Recruiting End Date" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Invite Link" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Status Meeting Date" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Delivery Duration" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Delivery Start Date" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                                <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Delivery End Date" TextAlign="center" />
                                </Styles.OrdersColumnContainer>
                            </Styles.OrdersHeaderRow>

                            {
                                Orders.map(Order => {
                                    var { OrderId, OrderNumber, InviteLink, AgreementDate, RecruitingStartDate, RecruitingEndDate, StatusMeetingDate, DeliveryDuration, DeliveryStartDate, DeliveryEndDate } = Order;

                                    var InviteLinkUrl = `${COMPANY_INVITE_URL}?code=${InviteLink}`;

                                    return (
                                        <Styles.OrdersBodyRow key={OrderId} className="OrdersBodyRow" onClick={() => this.onToggleShowInsertEditOrder({ InsertEditOrder: Order, ShowInsertEditOrder: true })}>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${OrderId}`} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${OrderNumber}`} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={DisplayTimeInUTC(AgreementDate)} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={DisplayTimeInUTC(RecruitingStartDate)} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={DisplayTimeInUTC(RecruitingEndDate)} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" OnClick={event => this.onCopyInviteLink({ event, InviteLinkUrl })} NoMargin Text={InviteLinkUrl} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={DisplayTimeInUTC(StatusMeetingDate)} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${DeliveryDuration} ${t('timeperiod_week_plural')}`} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={DisplayTimeInUTC(DeliveryStartDate)} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                            <Styles.OrdersColumnContainer className="OrdersColumnContainer">
                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={DisplayTimeInUTC(DeliveryEndDate)} TextAlign="center" />
                                            </Styles.OrdersColumnContainer>
                                        </Styles.OrdersBodyRow>
                                    );
                                })
                            }
                        </Styles.OrdersContainer>
                    </>
                }
            </Styles.OrdersAreaContainer>
        );
    }

    renderProducts = () => {
        var { t } = this.props;
        var { IsLoading, Products, Selected } = this.state.Filters.Products;
        var { Products: CustomProducts } = this.state.InsertEditOrder;

        return (
            <>
                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Custom prices for this order" TextAlign="left" />

                <Spacer Size="extra-small" />

                <Select
                    closeMenuOnSelect={false}
                    defaultValue={Selected}
                    getOptionLabel={option => option.ProductName}
                    getOptionValue={option => option.ProductId}
                    components={{ ValueContainer_Products }}
                    hideSelectedOptions={false}
                    isLoading={IsLoading}
                    isMulti
                    isSearchable
                    onChange={this.onChangeFilter_Products}
                    onFocus={this.onFocusFilter_Products}
                    options={Products}
                    placeholder={t('_select')}
                    styles={customStyles}
                />

                {
                    CustomProducts.length > 0 &&
                    <>
                        <Spacer Size="small" />

                        <Styles.CustomProductsContainer className="CustomProductsContainer">
                            {
                                CustomProducts.map(({ ActualPrice, ProductName, Price, ProductId }, CustomProductIndex) => {
                                    return (
                                        <Styles.CustomProductContainer className="CustomProductContainer" key={ProductId}>
                                            <MultiText TextAlign="left"
                                                Texts={[
                                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: ProductName },
                                                    { FontFamily: 'medium-italic', FontSize: 'medium-2', Text: ` (${ActualPrice} nok)` }
                                                ]}
                                            />

                                            <Styles.StyledAccountInput
                                                FontSize="medium-1"
                                                OnChange={event => this.onChangeCustomProductPrice({ event, index: CustomProductIndex })}
                                                Placeholder="Custom price in nok"
                                                Size="medium"
                                                Type="decimal"
                                                Value={Price}
                                            />
                                        </Styles.CustomProductContainer>
                                    );
                                })
                            }
                        </Styles.CustomProductsContainer>
                    </>
                }
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { TryingGetCompanies, TryingInsertEditCompany } = this.props;
        var { SearchCompanyName, ShowInsertEditCompanyModal } = this.state;

        var DownloadUploadButtons = [
            { BackgroundColor: "blue-abel", BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onDownloadEmployees({ CompanyId: 0, EmployeeType: 'Active' }), Title: t('download') },
            { BackgroundColor: "curus-green", BackgroundColorHover: 'curus-green-dark', Border: 'none', Color: 'white', ColorHover: 'white', Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onDownloadEmployeesData({ CompanyIds: `` }), Title: t('download_employee_data') }
        ];
        if ([ 1, 2, 6, 18097 ].indexOf(+this.props.UserDetails.UserId) > -1) DownloadUploadButtons.push({ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: EditIcon, IconPosition: 'left', IsInput: true, InputProps: { accept: "csv,.csv", id: "PendingEmployees", type: "file", onChange: this.onUploadEmployees }, Title: t('Upload') });

        return (
            <>
                {( TryingGetCompanies || (TryingInsertEditCompany && !ShowInsertEditCompanyModal)) && <Loading />}

                <Page>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin OnClick={() => this.onGetCompanies()} Text="Companies" TextAlign="center" />

                    <Spacer Size="medium" />

                    <Styles.SearchAndAddContainer className="SearchAndAddContainer">
                        <Styles.SearchCompaniesInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeSearchCompanyName}
                            Placeholder="Search by company name"
                            Size="medium"
                            Type="text"
                            Value={SearchCompanyName}
                        />

                        <ButtonGroup
                            Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: true }), Title: t('new_company') }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                        />
                    </Styles.SearchAndAddContainer>

                    <Spacer Size="medium" />

                    {this.renderAddEmployeesModal()}

                    <ButtonGroup
                        Buttons={DownloadUploadButtons}
                        ButtonWidth="fit-content"
                        ContainerWidth="fit-content"
                    />

                    <Spacer Size="medium" />

                    {this.renderDHCFilter()}

                    <Spacer Size="small" />

                    {this.renderCompanies()}
                </Page>

                {this.renderEmployeeOptionalsModal()}
                {this.renderInsertEditCompanyModel()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,

        TryingAddCompanyEmployees: state.Companies.TryingAddCompanyEmployees,
        TryingAddCompanyEmployeesError: state.Companies.TryingAddCompanyEmployeesError,
        TryingCompaniesAction: state.Companies.TryingCompaniesAction,
        TryingCompaniesActionError: state.Companies.TryingCompaniesActionError,
        TryingDeleteCompanyEmployees: state.Companies.TryingDeleteCompanyEmployees,
        TryingDeleteCompanyEmployeesError: state.Companies.TryingDeleteCompanyEmployeesError,
        TryingDownloadEmployees: state.Companies.TryingDownloadEmployees,
        TryingDownloadEmployeesError: state.Companies.TryingDownloadEmployeesError,
        TryingGetCompanies: state.Companies.TryingGetCompanies,
        TryingGetCompaniesError: state.Companies.TryingGetCompaniesError,
        TryingGetCompany: state.Companies.TryingGetCompany,
        TryingGetCompanyError: state.Companies.TryingGetCompanyError,
        TryingInsertEditCompany: state.Companies.TryingInsertEditCompany,
        TryingInsertEditCompanyError: state.Companies.TryingInsertEditCompanyError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddCompanyEmployees, DeleteCompanyEmployees, DownloadCompanyEmployeeData, DownloadCompanyEmployees, GetCompanies, GetCompany, GetOrderProducts, GetOrgMembers, InsertEditCompany, InsertEditOrder, MakeEmployeeUpdate, MarkOrderAsEnded, UpdateCompanyEmployees } )(CompaniesComponent));