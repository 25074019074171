import styled from 'styled-components';

import Popover from 'react-bootstrap/Popover';

import Icon from '../../Components/Icon';
import Input from '../../Components/Form/Input';

export const CompaniesBodyRow = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
            cursor: pointer;
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const CompaniesColumnContainer = styled.div``;

export const CompaniesContainer = styled.div`
    border-radius: 10px;
    overflow: hidden;
`;

export const CompaniesHeaderRow = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const CustomProductContainer = styled.div``;

export const CustomProductsContainer = styled.div``;

export const EmployeeSortingContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 10px;
`;

export const EmployeesBodyRow = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowActive = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(11, 1fr) 50px;
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowCompanyEnded = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowNotReached = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowNotStarted = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(4, 1fr) 50px;;
    width: 100%;

    ${
        props => !!props.ShowWarning &&
        `
            background-color: var(--warning);
        `
    }

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowPending = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowQuitPhase1 = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowQuitPhase1Plus = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesBodyRowSwitched = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const EmployeesColumnContainer = styled.div``;

export const EmployeesContainer = styled.div`
    border-radius: 10px;
    overflow: hidden;
`;

export const EmployeesHeaderRow = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowActive = styled.div`
    display: grid;
    grid-template-columns: repeat(11, 1fr) 50px;
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowCompanyEnded = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowNotReached = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowNotStarted = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr) 50px;
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowPending = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowQuitPhase1 = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowQuitPhase1Plus = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesHeaderRowSwitched = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const EmployeesSectionContainer = styled.div``;

export const FormQuestionContainer = styled.div``;

export const InputContainer = styled.div`
    margin: 0 auto;
    max-width: 500px;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const InputInnerContainer = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 9fr 1fr;
`;

export const InsertEditCompanyModalContainer = styled.div``;

export const InsertEditOrderContainer = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    padding: 10px 20px;
`;

export const InsertEditOrderInnerContainer = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
`;

export const InviteLinkContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 10px;
`;

export const OrdersAreaContainer = styled.div``;

export const OrdersContainer = styled.div`
    border-radius: 10px;
    overflow: hidden;
`;

export const OrdersBodyRow = styled.div`
    border-left: 2px solid var(--abel-blue);
    border-right: 2px solid var(--abel-blue);
    display: grid;
    grid-template-columns: 50px 50px 1fr 1fr 1fr 5fr 1fr 1fr 1fr 1fr;
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
            cursor: pointer;
        }
    }

    > div {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
    }

    :last-child {
        border-bottom: 2px solid var(--abel-blue);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;

export const OrdersColumnContainer = styled.div``;

export const OrdersHeaderRow = styled.div`
    display: grid;
    grid-template-columns: 50px 50px 1fr 1fr 1fr 5fr 1fr 1fr 1fr 1fr;
    width: 100%;

    > div {
        background-color: var(--abel-blue);
        color: var(--white);
        padding: 10px;
    }
`;

export const SearchAndAddContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
        flex: 1;
    }
`;

export const SearchCompaniesInput = styled(Input)`
    margin-right: 10px;

    > input {
        border-radius: 10px;
    }
`;

export const StyledAccountInput = styled(Input)`
    max-width: 300px;
    
    > input {
        border-radius: 10px;
    }
`;

export const StyledCompanyName = styled(Input)`
    > div {
        font-family: var(--font-family-medium);
        font-size: var(--font-size-medium-3);
        margin-bottom: 20px;
        text-align: center;
    }

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledDeleteIcon = styled(Icon)`
    height: 20px;
    margin-left: 10px;
    width: 20px;
`;

export const StyledInput = styled(Input)`
    background-color: var(--white-concrete);
    border: none; 
    border-radius: 10px;

    > input {
        background-color: var(--white-concrete);
    }
`;

export const StyledMoreIcon = styled(Icon)`
    height: 20px;
    width: 20px;
`;

export const StyledPopover = styled(Popover)`
    background-color: var(--white-concrete);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;
    padding: 15px;
    max-width: 300px;
    width: 300px;
`;

export const ToggleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div:first-child {
        margin-right: 10px;
    }
`;